// custom modules
import administration from '../routes/app/routes/administration/store/'
import cms from '../routes/app/routes/cms/store/'
import blog from '../routes/app/routes/blog/store/'

export default {
  administration,
  cms,
  blog,
}
