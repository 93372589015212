const { SERVER_IP } = require('./server')

export default {
  url: 'https://' + SERVER_IP + ':8000',
  path: {
    prefix: 'api',
    storage: 'storage',
    upload: 'files/file-upload',
  },
}
