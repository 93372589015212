import Vue from 'vue'
import i18n from './locales/index'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import './registerServiceWorker'
import './plugins/http'
import './plugins/ie'
import './plugins/custom/'
import App from './App.vue'

// vee-validate
import { ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

// vue script loader
// import LoadScript from 'vue-plugin-load-script'
// Vue.use(LoadScript)

// vue-head
// import VueHead from 'vue-head'
// Vue.use(VueHead)

// load html
// import htmlImport from '../public/bokgiapp/bokgiapp.html'
// Vue.component('htmlImport', htmlImport)
//

// Vue.component('htmlpage', function (resolve, reject) {
//     Vue.http.get('/bokgiapp/bokgiapp.html', function (data, status, request) {
//         var parser = new DOMParser()
//         var doc = parser.parseFromString(data, 'text/html')
//         resolve({
//             template: doc,
//         })
//     })
// })

Vue.http.interceptors.push((request, next) => {
  if (localStorage.getItem('token')) {
    request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
  }
  next(response => {
    if (response.status === 400 || response.status === 401 || response.status === 403) {
      store.commit('auth/logout')
      router.push({ path: '/login' })
    }
  })
})

extend('required', {
  ...required,
  message: 'This field is required',
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  components: {
    ValidationProvider,
  },
  render: h => h(App),
}).$mount('#app')
