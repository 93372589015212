<template>
  <login-form
    redirect="/dashboard"
    :locale-selectable="true"
    :show-logo="false"
    logo="logo.png"
  ></login-form>
</template>

<script>

import LoginForm from '../../utils/auth/components/LoginForm.vue'

export default {
  components: {
    LoginForm,
  },
}

</script>
