export default {
  dashboard: {
    name: 'Strona główna',
  },
  cms: {
    name: 'CMS',
    settings: 'Ustawienia',
    messages: 'Wiadomości',
    menuItems: 'Elementy menu',
  },
  store: {
    name: 'Sklep',
    sections: 'Kategorie produktów',
    products: 'Produkty',
    transactions: 'Transakcje',
    customers: 'Klienci',
    transactionProducts: 'Transakcje - produkty',
  },
  blog: {
    name: 'Blog',
    categories: 'Kategorie',
    tags: 'Tagi',
    posts: 'Posty',
    postTags: 'Posty - tagi',
  },
  admin: {
    name: 'Administracja',
    permissions: 'Uprawnienia',
    users: 'Użytkownicy',
    userPermissions: 'Użytkownicy - uprawnienia',
    userTypes: 'Typy użytkowników',
  },
}
