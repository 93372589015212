import Vue from 'vue'
import Router from 'vue-router'
import appRoutes from './routes/app/router.js'
import store from './store/'

import App from './routes/app/Index.vue'
import Login from './routes/login/Index.vue'
import ProblemLogin from './routes/problemlogin/Index.vue'

import Home from './views/Home.vue'
// import Problem from './views/Problem.vue'
// import Testbokgi from './views/Testbokgi.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        title: 'Good Intelligence',
        guest: true,
      },
      component: Home,
    },
    // {
    //   path: '/project',
    //   name: 'project',
    //   component: Project,
    //   meta: { guest: true },
    // },
    // {
    //   path: '/team',
    //   name: 'team',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: Team,
    //   meta: { guest: true },
    // },
    // register
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: Register,
    //   meta: { guest: true },
    // },
    // login
    {
      path: '/dashboard',
      name: 'app',
      component: App,
      children: appRoutes,
      beforeEnter: (to, from, next) => {
        var auth = localStorage.getItem('token')
        if (!auth) {
          store.commit('auth/logout')
          next('/login')
        } else {
          Vue.http.get('auth/user')
            .then(response => {
              next()
            }, response => {
              next('/login')
            })
        }
      },
    },
    {
      path: '/adminboard',
      name: 'app',
      component: App,
      children: appRoutes,
      meta: { guard: 'ADMIN' },
      beforeEnter: (to, from, next) => {
        var auth = localStorage.getItem('token')
        if (!auth) {
          store.commit('auth/logout')
          next('/login')
        } else {
          Vue.http.get('auth/user')
            .then(response => {
              next()
            }, response => {
              next('/login')
            })
        }
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/problem',
      name: 'problemlogin',
      component: ProblemLogin,
    },
    {
      path: '/problemboard',
      name: 'app',
      component: App,
      children: appRoutes,
      beforeEnter: (to, from, next) => {
        var auth = localStorage.getItem('token')
        if (!auth) {
          store.commit('auth/logout')
          next('/problem')
        } else {
          Vue.http.get('auth/user')
            .then(response => {
              next()
            }, response => {
              next('/problem')
            })
        }
      },
    },
    // {
    //   path: '/testbokgi',
    //   name: 'testbokgi',
    //   component: Testbokgi,
    // },
  ],
})

router.beforeEach(function (to, from, next) {
  let middleware
  store.state.page = null
  to.matched.some(m => {
    middleware = m.meta.guard
  })
  if (typeof middleware === 'undefined') {
    next()
  } else {
    if (store.getters['auth/checkPermission'](middleware)) {
      window.scrollTo(0, 0)
      next()
    } else if (store.getters['auth/isLogged']) {
      if (store.getters['auth/userInfo.name'] === 'badugi@goodi4u.com') {
        next('/adminboard')
      } else {
        next('/dashboard')
      }
    } else {
      next('/login')
    }
  }
})

export default router
