/* eslint-disable */

var prepare_dom = function() {
	prepare_dom_box.call(this,"white");
	// prepare_dom_box.call(this,"black");
	this.element.appendChild(this.white.box);
	// this.element.appendChild(this.black.box);
}

var prepare_dom_box = function(type) {
	this[type] = {};
	var t = this[type];
	t.box = document.createElement("div");
	t.box.className = "wgo-box-wrapper wgo-player-wrapper wgo-"+type;

	t.name = document.createElement("div");
	t.name.className = "wgo-chart-wrapper";
	t.box.appendChild(t.name);
    
	t.chart = document.createElement("canvas");
	t.chart.className = "wgo-chart";
	t.chart.setAttribute("id", "chart")
	t.name.appendChild(t.chart);

	t.autoplay_wrapper = document.createElement("div");
	t.autoplay_wrapper.className = "wgo-autoplay-wrapper";
	t.box.appendChild(t.autoplay_wrapper);

	t.autoplay1 = document.createElement("div");
	t.autoplay2 = document.createElement("div");
	t.autoplay3 = document.createElement("div");
	t.autoplay1.setAttribute("id", "autoplay1")
	t.autoplay2.setAttribute("id", "autoplay2")
	t.autoplay3.setAttribute("id", "autoplay3")
	t.autoplay1.className = "wgo-autoplay";
	t.autoplay2.className = "wgo-autoplay";
	t.autoplay3.className = "wgo-autoplay";

	t.autoplay_wrapper.appendChild(t.autoplay1);
	t.autoplay_wrapper.appendChild(t.autoplay2);
	t.autoplay_wrapper.appendChild(t.autoplay3);
}

var ChartBox = WGo.extendClass(WGo.BasicPlayer.component.Component, function(player) {
	this.super(player);
	this.element.className = "wgo-infobox";
	
	prepare_dom.call(this);

	// player.addEventListener("kifuLoaded", kifu_loaded.bind(this));
	// player.addEventListener("update", update.bind(this));

});

WGo.BasicPlayer.component.ChartBox = ChartBox;

/* add Root button */
WGo.i18n.en.root = "Root"
WGo.i18n.en.analyze = "Analysis"
WGo.i18n.en.hovering = "Hovering"
WGo.i18n.en.chart = "Chart"

var butupd_first = function(e) {
	if(!e.node.parent && !this.disabled) this.disable();
	else if(e.node.parent && this.disabled) this.enable();
}

var butupd_last = function(e) {
	if(!e.node.children.length && !this.disabled) this.disable();
	else if(e.node.children.length && this.disabled) this.enable();
}
var but_frozen = function(e) {
	this._disabled = this.disabled;
	if(!this.disabled) this.disable();
}

var but_unfrozen = function(e) {
	if(!this._disabled) this.enable();
	delete this._disabled;
}

var butupd_root = function(player, e) {
	if(!player.remember_root) this.disable();
	else if(player.remember_root) this.enable();
}

var butupd_analyze = function(e) {
	this.enable();
}

var butupd_reverse_root = function(player, e) {
	if(!e.node.parent && !this.disabled) this.disable();
	else if(e.node.parent && this.disabled) this.enable();

	if(player.remember_root === true) this.disable();
	else if(player.remember_root === false && player.kifuReader.path.m != 0) this.enable();
}

WGo.i18n.en["playmode"] = "Play here";

WGo.BasicPlayer.component.Control.menu.push({
	constructor: WGo.BasicPlayer.control.MenuItem,
	args: {
		name: "playmode",
		togglable: true,
		click: function(player) { 
        	localStorage.setItem('play_kifu', player.kifuReader.game.stack[player.kifuReader.game.stack.length-1].schema)
        	localStorage.setItem('play_move_save', player.kifuReader.path.m)
			var win = window.open('http://192.168.0.48:8082/#/playbokgi', '_blank');
			win.focus()
		},
	}
});

var root = {
    constructor: WGo.BasicPlayer.control.Button,
    args: {
        name: "root",
        disabled: true,
        init: function(player) {
            player.addEventListener("update", butupd_root.bind(this, player));
            player.addEventListener("frozen", but_frozen.bind(this));
            player.addEventListener("unfrozen", but_unfrozen.bind(this));
        },
        click: function(player) { 
            var i = 0;
            while (true) {
                player.previous();
                if (player.kifuReader.path.m === player.root_path) {
                    player.remember_root = false
                    break
                }

                i += 1;
                if (i > 100) {
                    console.log("error")
                    break;
                }
            }
            player.next(0);
            player.previous();
            player.lastevent = 'root'
            player.update();
        },
    }
}

var analyze = {
    constructor: WGo.BasicPlayer.control.Button,
    args: {
        name: "analyze",
        disabled: false,
        init: function(player) {
            player.addEventListener("update", butupd_analyze.bind(this));
            player.addEventListener("frozen", but_frozen.bind(this));
            player.addEventListener("unfrozen", but_unfrozen.bind(this));
        },
        click: function(player) { 
            const anal_icon = document.getElementsByClassName('material-icons anal')[0]
            if (player.is_anal === 2) {
                anal_icon.innerHTML = 'brightness_3'
                player.listeners.update[0] = player.unanal
                player.is_anal = 1

                // player.board.removeEventListener('mousemove', player.hover_function)
            } else if (player.is_anal === 1) {
                anal_icon.innerHTML = 'brightness_2'
                player.listeners.update[0] = player.halfanal
                player.is_anal = 0
            } else if (player.is_anal === 0) {
                anal_icon.innerHTML = 'brightness_1'
                player.listeners.update[0] = player.anal
                player.is_anal = 2

                // player.board.addEventListener('mousemove', player.hover_function)
            }
            player.update()

            // var temp_update_func = player.listeners.update[0]
            // player.listeners.update[0] = player.save_update_func
            // player.save_update_func = temp_update_func
            // player.update();
            // const anal_icon = document.getElementsByClassName('material-icons anal')[0]
            // if (player.is_anal === true) {
            //     anal_icon.innerHTML = 'brightness_2'
            //     anal_icon.style.setProperty('color', 'grey')
            //     player.is_anal = false
            //     player.board.removeEventListener('mousemove', player.hover_function)
            // } else {
            //     anal_icon.style.setProperty('color', '')
            //     player.is_anal = true
            //     player.board.addEventListener('mousemove', player.hover_function)
            // }
        },
    }
}

var hovering = {
    constructor: WGo.BasicPlayer.control.Button,
    args: {
        name: "hovering",
        disabled: false,
        init: function(player) {
            player.addEventListener("update", butupd_analyze.bind(this));
            player.addEventListener("frozen", but_frozen.bind(this));
            player.addEventListener("unfrozen", but_unfrozen.bind(this));
        },
        click: function(player) { 
            const hover_icon = document.getElementsByClassName('material-icons hover')[0]
            if (player.is_hover === 0) {
                hover_icon.innerHTML = 'gps_fixed'
                player.board.addEventListener('mousemove', player.hover_function)
                player.is_hover = 1
            } else if (player.is_hover === 1) {
                hover_icon.innerHTML = 'gps_off'
                player.board.removeEventListener('mousemove', player.hover_function)
                player.is_hover = 0
            }
            player.update()
        },
    }
}

var chart = {
    constructor: WGo.BasicPlayer.control.Button,
    args: {
        name: "chart",
        disabled: false,
        init: function(player) {
            player.addEventListener("update", butupd_analyze.bind(this));
            player.addEventListener("frozen", but_frozen.bind(this));
            player.addEventListener("unfrozen", but_unfrozen.bind(this));
        },
        click: function(player) { 
            console.log(player)
            const player_div = document.getElementById('player')
            const right_wrapper = document.getElementsByClassName('wgo-player-right-wrapper')[0]
            if (player.is_chart === 0) {
                right_wrapper.setAttribute('style', 'visibility:visible')
                player_div.setAttribute('style', 'left:30%')
                player.is_chart = 1
            } else if (player.is_chart === 1) {
                right_wrapper.setAttribute('style', 'visibility:hidden')
                player_div.setAttribute('style', '')
                player.is_chart = 0
            }
            player.update()
        },
    }
}

var first = {
			constructor:  WGo.BasicPlayer.control.Button,
			args: {
				name: "first",
				disabled: true,
				init: function(player) {
					// player.addEventListener("update", butupd_first.bind(this));
					player.addEventListener("update", butupd_reverse_root.bind(this, player));
					player.addEventListener("frozen", but_frozen.bind(this));
					player.addEventListener("unfrozen", but_unfrozen.bind(this));
				},
				click: function(player) { 
					player.first();
                    player.lastevent = 'first'
					player.update();
				},
			}
		} 

var multiprev = {
			constructor:  WGo.BasicPlayer.control.Button,
			args: {
				name: "multiprev",
				disabled: true,
				multiple: true,
				init: function(player) {
					// player.addEventListener("update", butupd_first.bind(this));
					player.addEventListener("update", butupd_reverse_root.bind(this, player));
					player.addEventListener("frozen", but_frozen.bind(this));
					player.addEventListener("unfrozen", but_unfrozen.bind(this));
				},
				click: function(player) { 
					var p = WGo.clone(player.kifuReader.path);
					p.m -= 10; 
					player.goTo(p);
                    player.lastevent = 'multiprev'
					player.update();
				},
			}
		}

var previous = {
			constructor: WGo.BasicPlayer.control.Button,
			args: {
				name: "previous",
				disabled: true,
				multiple: true,
				init: function(player) {
					player.addEventListener("update", butupd_first.bind(this));
					player.addEventListener("frozen", but_frozen.bind(this));
					player.addEventListener("unfrozen", but_unfrozen.bind(this));
				},
				click: function(player) { 
					player.previous();
                    player.lastevent = 'previous'
					player.update();
				},
			}
		}

var next = {
			constructor: WGo.BasicPlayer.control.Button,
			args: {
				name: "next",
				disabled: true,
				multiple: true,
				init: function(player) {
					player.addEventListener("update", butupd_last.bind(this));
					player.addEventListener("frozen", but_frozen.bind(this));
					player.addEventListener("unfrozen", but_unfrozen.bind(this));
				},
				click: function(player) { 
                    player.lastevent = 'next'
					player.next();
				},
			}
		}

var multinext = {
			constructor: WGo.BasicPlayer.control.Button,
			args: {
				name: "multinext",
				disabled: true,
				multiple: true,
				init: function(player) {
					player.addEventListener("update", butupd_last.bind(this));
					player.addEventListener("frozen", but_frozen.bind(this));
					player.addEventListener("unfrozen", but_unfrozen.bind(this));
				},
				click: function(player) { 
                    player.lastevent = 'multinext'
					var p = WGo.clone(player.kifuReader.path);
					p.m += 10; 
					player.goTo(p);
				},
			}
		}

var last = {
			constructor: WGo.BasicPlayer.control.Button,
			args: {
				name: "last",
				disabled: true,
				init: function(player) {
                    player.lastevent = 'last'
					player.addEventListener("update", butupd_last.bind(this));
					player.addEventListener("frozen", but_frozen.bind(this));
					player.addEventListener("unfrozen", but_unfrozen.bind(this));
				},
				click: function(player) {
                    player.lastevent = 'last'
					player.last()
				},
			}
		}

WGo.BasicPlayer.component.Control.widgets[2].args.widgets.splice(0, 3, first, multiprev, previous)
WGo.BasicPlayer.component.Control.widgets[2].args.widgets.splice(4, 3, next, multinext, last)

WGo.BasicPlayer.component.Control.widgets[1].args.widgets.pop()
WGo.BasicPlayer.component.Control.widgets[1].args.widgets.push(hovering)
WGo.BasicPlayer.component.Control.widgets[1].args.widgets.push(analyze)
WGo.BasicPlayer.component.Control.widgets[1].args.widgets.push(root)

WGo.BasicPlayer.component.Control.widgets[0].args.widgets.push(chart)

/* change layout */
var layouts = {
	"large": {
		top: [],
		right: [],
		bottom: []
	},
	"small": {
		top: [],
		right: [],
		bottom: []
	},
	"phone": {
		top: [],
		right: [],
		bottom: []
	},
};

var dynamicLayout = [
	{
		// conditions: {
		// 	minWidth: 650,
		// },
		layout: layouts["large"], 
		className: "wgo-twocols wgo-large",
	},
	{
		// conditions: {
		// 	minWidth: 550,
		// 	minHeight: 600,
		// },
		layout: layouts["small"],
		className: "wgo-twocols wgo-large"
	},
];

layouts["large"].right.push("CommentBox");
layouts["large"].right.push("ChartBox");
layouts["large"].top.push("InfoBox");
layouts["large"].bottom.push("Control");

WGo.BasicPlayer.default = {
	// layout: dynamicLayout,
	layout: dynamicLayout,
}

/* change draw function */
var is_here_stone = function(board, x, y) {
	return (board.obj_arr[x][y][0] && board.obj_arr[x][y][0].c == WGo.W || board.obj_arr[x][y][0].c == WGo.B);
}

var theme_variable = function(key, board) {
	return typeof board.theme[key] == "function" ? board.theme[key](board) : board.theme[key];
}

var redraw_layer = function(board, layer) {
	var handler;

	board[layer].clear();
	board[layer].draw(board);

	for(var x = 0; x < board.size; x++) {
		for(var y = 0; y < board.size; y++) {
			for(var z = 0; z < board.obj_arr[x][y].length; z++) {
				var obj = board.obj_arr[x][y][z];
				if(!obj.type) handler = board.stoneHandler;
				else if(typeof obj.type == "string") handler = WGo.Board.drawHandlers[obj.type];
				else handler = obj.type;

				if(handler[layer]) handler[layer].draw.call(board[layer].getContext(obj), obj, board);
			}
		}
	}

	for(var i = 0; i < board.obj_list.length; i++) {
		var obj = board.obj_list[i];
		var handler = obj.handler;

		if(handler[layer]) handler[layer].draw.call(board[layer].getContext(obj.args), obj.args, board);
	}
}

WGo.Board.drawHandlers.LB = {
    stone: {
        draw: function(args, board) {
            var xr = board.getX(args.x),
                yr = board.getY(args.y),
                sr = board.stoneRadius,
                prefont = args.prefont || "";
                font = args.font || theme_variable("font", board) || "";

            this.fillStyle = args.c || get_markup_color(board, args.x, args.y);
            // if(args.text.length == 1) this.font = Math.round(sr*1.5)+"px "+font;
            // else if(args.text.length == 2) this.font = Math.round(sr*1.2)+"px "+font;
            // else this.font = Math.round(sr)+"px "+font;

            this.beginPath();
            this.textBaseline="middle";
            this.textAlign="center";

            this.arc(xr-board.ls, yr-board.ls, sr, 0, 2*Math.PI, true);

            if (typeof args.text == "string") {
                if(args.text.length == 1) this.font = prefont + Math.round(sr*1.5)+"px "+font;
                else if(args.text.length == 2) this.font = prefont + Math.round(sr*1.2)+"px "+font;
                else this.font = prefont + Math.round(sr)+"px "+font;

                this.fillText(args.text, xr, yr, 2*sr);
            } else {
                this.fillStyle = args.c_fill;
                this.fill();
                this.lineWidth = args.width_circle;
                this.strokeStyle = args.c_circle;
                this.stroke();

                var put_text = '';
                for (var i=0; i<3; i++) {
                    if (i === 0) {
                        if (this.fillStyle != "#0000ff") {
                            this.fillStyle = "rgba(0,0,0,1.0)";
                        } else {
                            this.fillStyle = "rgba(250,237,39,1.0)";
                        }
                        this.textBaseline="bottom";
                        put_text = String(Number(args.text[i]).toFixed(1))
                    } else if (i === 1) {
                        this.textBaseline="middle";
                        if (args.text[i].length > 3) {
                            put_text = String(Math.round(Number(args.text[i])/1000)) + "k"
                        } else {
                            put_text = String(args.text[i])
                        }
                    } else if (i === 2){
                        this.textBaseline="top";
                        put_text = String(Number(args.text[i]).toFixed(1))
                    }

                    this.font = prefont + Math.round(sr/1.3/Math.pow(put_text.length, 0.15))+"px "+font;

                    var y_sub = -5 + (5*i);
                    this.fillText(put_text, xr, yr+y_sub, 2*sr);

                }
            }
        },
    },

    // modifies grid layer too
    grid: {
        draw: function(args, board) {
            if(!is_here_stone(board, args.x, args.y) && !args._nodraw) {
                var xr = board.getX(args.x),
                    yr = board.getY(args.y),
                    sr = board.stoneRadius;
                this.clearRect(xr-sr,yr-sr,2*sr,2*sr);
            }
        },
        clear: function(args, board) {
            if(!is_here_stone(board, args.x, args.y))  {
                args._nodraw = true;
                redraw_layer(board, "grid");
                delete args._nodraw;
            }
        }
    },
}


var detect_scrolling = function(node, bp) {
	if(node == bp.element || node == bp.element) return false;
	else if(node._wgo_scrollable || (node.scrollHeight > node.offsetHeight && window.getComputedStyle(node).overflow == "auto")) return true;
	else return detect_scrolling(node.parentNode, bp);
}

var wheel_lis = function(e) {
	var delta = e.wheelDelta || e.detail*(-1);

	// if there is scrolling in progress within an element, don't change position
	if(detect_scrolling(e.target, this)) return true;

	if(delta < 0) {
        if (this.kifuReader.node.children.length != 0) {
            this.lastevent = 'wheel'
            this.next();
        }
		if(this.config.lockScroll && e.preventDefault) e.preventDefault();
		return !this.config.lockScroll;
	}
	else if(delta > 0) {
        if (this.kifuReader.node.parent != null) {
            this.previous();
            this.lastevent = 'wheel'
            this.update();
        }
		if(this.config.lockScroll && e.preventDefault) e.preventDefault();
		return !this.config.lockScroll;
	}
	return true;
};

WGo.Player.prototype.setWheel = function(b) {
		if(!this._wheel_listener && b) {
			this._wheel_listener = wheel_lis.bind(this);
			var type = (document.onmousewheel !== undefined) ? "mousewheel" : "DOMMouseScroll";
			this.element.addEventListener(type, this._wheel_listener);
		}
		else if(this._wheel_listener && !b) {
			var type = (document.onmousewheel !== undefined) ? "mousewheel" : "DOMMouseScroll";
			this.element.removeEventListener(type, this._wheel_listener);
			delete this._wheel_listener;
		}
	}
