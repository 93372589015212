<template>
<div>
  <div id="player" style="width: 160vh;"></div>
  <!--canvas id="chart" style="width: 100%; height:100%"></canvas-->
</div>
</template>

<script>
/* eslint-disable */
const { SERVER_IP } = require('../../../../config/server')

import axios from 'axios'
import Chart from 'chart.js'
import './assets/JSON-js/cycle.js'
import './assets/wgo/wgo.js'
import './assets/wgo/kifu.js'
import './assets/wgo/sgfparser.js'
import './assets/wgo/player.js'
import './assets/wgo/basicplayer.js'
import './assets/wgo/basicplayer.component.js'
import './assets/wgo/basicplayer.commentbox.js'
import './assets/wgo/basicplayer.infobox.js'
import './assets/wgo/basicplayer.control.js'
import './assets/wgo/player.editable.js'
import './assets/wgo/scoremode.js'
import './assets/bokgi.js'
import { mapActions } from 'vuex'

export default {
  name: 'bokgiapp',
  data () {
    return {
      loaded: false,
      htmlCode: null,
      sgfGibo:  '',
      charts: '',
      filename: ''
    }
  },

  methods: {
    async readData () {
      const before_url = localStorage.getItem('url')
      if (before_url === 'problemboard') {
        const filename = localStorage.getItem('fn')
        this.filename = filename

        const payload = {
          file_name: filename,
        }

        this.is_problem = true
        this.is_bokgi = false

        const path = 'https://' + SERVER_IP + ':8000/download/problem_review'
        await axios.post(path, payload)
          .then(response => {
            this.sgfGibo = response.data.gibo
            return console.log('read ok')
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        const username = localStorage.getItem('ui')
        const filename = localStorage.getItem('fn')
        this.filename = filename
        const filedate = localStorage.getItem('fd')

        const payload = {
          user_email: username,
          file_name: filename,
          file_date: filedate,
        }

        this.is_problem = false
        var path
        if (filedate === "undefined") {
          this.is_bokgi = false
          path = 'https://' + SERVER_IP + ':8000/download/merge'
        } else {
          this.is_bokgi = true
          path = 'https://' + SERVER_IP + ':8000/download/review'
        }

        await axios.post(path, payload)
          .then(response => {
            this.sgfGibo = response.data.gibo
            this.charts = response.data.charts
            return console.log('read ok')
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    async loadPlayer () {
      var elem = document.getElementById("player")
      this.player = new WGo.BasicPlayer(elem, {
        sgf: this.sgfGibo,
      })
      this.player.eventLog = []
      this.player.is_anal = 2
      this.player.is_hover = 1
      this.player.is_limit = false
      this.player.hover = false
      this.player.remember_root = false
    },
    makeChart (is_bokgi, charts) {
        if (is_bokgi === true) {
            var ctx = document.getElementById('chart').getContext('2d');
            // document.getElementById('chart').height = 180

            Chart.plugins.register({
                afterDatasetsDraw: function(chart) {
                    if (chart.tooltip._active && chart.tooltip._active.length) {
                        var activePoint = chart.tooltip._active[0],
                            ctx = chart.ctx,
                            y_axis = chart.scales['y-axis-0'],
                            x = activePoint.tooltipPosition().x,
                            topY = y_axis.top,
                            bottomY = y_axis.bottom;
                        // draw line
                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 3;
                        ctx.strokeStyle = '#07C';
                        ctx.stroke();
                        ctx.restore();
                     }
                }
            });

            const verticalLinePlugin = {
                getLinePosition: function (chart, pointIndex) {
                    const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
                    const data = meta.data;
                    return data[pointIndex]._model.x;
                },
                renderVerticalLine: function (chartInstance, pointIndex) {
                    const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex);
                    const scale = chartInstance.scales['y-axis-0'];
                    const context = chartInstance.chart.ctx;

                    // render vertical line
                    context.beginPath();
                    context.strokeStyle = '#ff0000';
                    context.moveTo(lineLeftOffset, scale.top);
                    context.lineTo(lineLeftOffset, scale.bottom);
                    context.stroke();

                    // write label
                    context.fillStyle = "#ff0000";
                    context.textAlign = 'center';
                    context.fillText('', lineLeftOffset, (scale.bottom - scale.top) / 2 + scale.top);
                },

                afterDatasetsDraw: function (chart, easing) {
                    if (chart.config.lineAtIndex) {
                        chart.config.lineAtIndex.forEach(pointIndex => this.renderVerticalLine(chart, pointIndex));
                    }
                }
            };
            Chart.plugins.register(verticalLinePlugin);
                
            Chart.pluginService.register({
                beforeDraw: function(chartInstance, easing) {
                    var lineOpts = chartInstance.options.drawHorizontalLine;
                    if (lineOpts) {
                        var yAxis = chartInstance.scales["y-axis-0"];
                        var yValueStart = yAxis.getPixelForValue(lineOpts.lineY[0], 0, 0, true);
                        var yValueEnd = yAxis.getPixelForValue(lineOpts.lineY[1], 0, 0, true);

                        var xAxis = chartInstance.scales["x-axis-0"];
                        var xValueStart = xAxis.getPixelForTick(0) - 5;
                        var xValueEnd = xAxis.right;

                        var ctx = chartInstance.chart.ctx;
                        ctx.save();

                        // le texte
                        ctx.font = lineOpts.textFont;
                        ctx.fillStyle = lineOpts.textColor;
                        ctx.fillText(lineOpts.text, lineOpts.textPosition, yValueStart + 8);

                        // la ligne en pointillés
                        ctx.setLineDash([0, 0]);
                        ctx.strokeStyle = lineOpts.lineColor;
                        ctx.lineWidth = 1.0;
                        ctx.beginPath();
                        ctx.moveTo(xValueStart, yValueStart);
                        ctx.lineTo(xValueEnd, yValueEnd);
                        ctx.stroke();

                        ctx.restore();
                    }
                }
            });

            this.myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: charts.split(" ")[0].split(","),
                    datasets: [
                        {
                            label: "B  ",
                            fill: false,
                            data: charts.split(" ")[1].split(","),
                            pointRadius: 1,
                            pointStyle: "line",
                            borderWidth: 1,
                            borderColor: "#000000",
                            backgroundColor: "#000000",
                            spanGaps:true
                        },
                        {
                            label: "B_best   ",
                            fill: false,
                            data: charts.split(" ")[3].split(","),
                            pointRadius: 3,
                            pointStyle: "circle",
                            borderWidth: 1,
                            borderColor: "#0000CD",
                        },
                        {
                            label: "W  ",
                            fill: false,
                            data: charts.split(" ")[2].split(","),
                            pointRadius: 1,
                            pointStyle: "line",
                            borderWidth: 1,
                            borderColor: "#FF007F",
                            backgroundColor: "#FF007F",
                            spanGaps:true
                        },
                        {
                            label: "W_best",
                            fill: false,
                            data: charts.split(" ")[4].split(","),
                            pointRadius: 3,
                            pointStyle: "triangle",
                            borderWidth: 1,
                            borderColor: "#660033",
                        }
                    ],
                },
                lineAtIndex: [0],
                options: {
                    drawHorizontalLine: {
                      lineY: [50, 50],
                      lineColor: "rgba(255, 140, 0, 1.0)",
                    },
                    legend: {
                        display:true,
                        labels: {
                            usePointStyle: true
                        }
                    },
                    hover: {
                        mode: "x-axis"
                    },
                    tooltips: {
                        mode: "x-axis"
                    },
                    elements: {
                        line: {
                            tension: 0
                        }
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            ticks: {
                                autoSkip: false,
                                callback: function(label, index, labels) {
                                    if (label % 10 == 0) {
                                        if (label % 20 == 0) {
                                            return label;
                                        } else {
                                            return "";
                                        }
                                    }
                                }
                            },
                            gridLines: {
                                display:true
                            }
                        }],
                        yAxes: [{
                            display: true,
                            ticks: {
                                callback: function(label, index, labels) {
                                    if (label == 0) {
                                        return "";
                                    } else if (label == 10) {
                                        return label;
                                    } else if (label == 30) {
                                        return label;
                                    } else if (label == 50){
                                        return label;
                                    } else if (label == 70){
                                        return label;
                                    } else if (label == 90){
                                        return label;
                                    } else {
                                        return "";
                                    }
                                },
                                min: 0,
                                max: 100
                            },
                            gridLines: {
                                display:true
                            }
                        }],
                    },
                } 
            });

        } else {
            var ctx = document.getElementById('chart').getContext('2d');
            this.myChart = new Chart(ctx, {type:'line'})
        }
    },
    chartListener (is_bokgi) {
        if (is_bokgi === true) {
            var canvas = document.getElementById("chart");
            var player = this.player
            var myChart = this.myChart

            canvas.onclick = function (e) {
                var point = myChart.getElementsAtXAxis(e)[0];
                var move = point._index;
                var yTop = myChart.chartArea.top;
                var yBottom = myChart.chartArea.bottom;

                if (event.offsetY <= yBottom && event.offsetY >= yTop && player.remember_root === false) {
                    player.goTo(move);
                }
            };

            var update_chart = function(e) {
                if (myChart.scales['x-axis-0'].max >= this.kifuReader.path.m && this.remember_root === false && this.hover === false){
                    myChart.config.lineAtIndex = [this.kifuReader.path.m];
                    myChart.update();
                }
            }
            player.addEventListener('update', update_chart.bind(player))
        }
    },
    boxListener (player) {
        const info_list = document.getElementsByClassName('wgo-info-list')[0]
        const filename_div = document.createElement('div');
        const filename_span1 = document.createElement('span');
        const filename_span2 = document.createElement('span');
        const filename_content1 = document.createTextNode('filename');
        const filename_content2 = document.createTextNode(this.filename);
        filename_span1.appendChild(filename_content1)
        filename_span2.appendChild(filename_content2)
        filename_span1.className = "wgo-info-label"
        filename_span2.className = "wgo-info-value"
        filename_div.appendChild(filename_span1)
        filename_div.appendChild(filename_span2)
        filename_div.className = "wgo-info-item"
        info_list.appendChild(filename_div)
        player.removeEventListener("update", player.components.CommentBox._update);
    },
    anal (is_problem, player) {
        const icon = document.createElement('i');
        icon.className = 'material-icons anal';
        icon.innerHTML = 'brightness_1'

        const anal_button = document.getElementsByClassName('wgo-button wgo-button-analyze')[0]
        anal_button.appendChild(icon)
        if (is_problem === true) {
            anal_button.click()
            player.last()
            player.previous()
        } else {
        }
    },
    hover (is_problem, player) {
        const icon = document.createElement('i');
        icon.className = 'material-icons hover';
        icon.innerHTML = 'gps_fixed'

        const hover_button = document.getElementsByClassName('wgo-button wgo-button-hovering')[0]
        hover_button.appendChild(icon)
        if (is_problem === true) {
            hover_button.click()
        } else {
        }
    },
    chart (is_problem, player) {
        const icon = document.createElement('i');
        icon.className = 'material-icons chart';
        icon.innerHTML = 'timeline'

        const chart_button = document.getElementsByClassName('wgo-button wgo-button-chart')[0]
        chart_button.appendChild(icon)

        const xxx1 = document.getElementById('autoplay1');
        const xxx_button1 = document.createElement('button');
        xxx_button1.className = 'autoplay';
        xxx_button1.innerHTML = 'Autoplay 1.0s';
        xxx1.appendChild(xxx_button1);

        const xxx2 = document.getElementById('autoplay2');
        const xxx_button2 = document.createElement('button');
        xxx_button2.className = 'autoplay';
        xxx_button2.innerHTML = 'Autoplay 3.0s';
        xxx2.appendChild(xxx_button2);

        const xxx3 = document.getElementById('autoplay3');
        const xxx_button3 = document.createElement('button');
        xxx_button3.className = 'autoplay';
        xxx_button3.innerHTML = 'Autoplay 5.0s';
        xxx3.appendChild(xxx_button3);

        const anal_button = document.getElementsByClassName('wgo-button wgo-button-analyze')[0]
        var type = (document.onmousewheel !== undefined) ? "mousewheel" : "DOMMouseScroll";
        player.stop_auto = true

        var timer = 0;
        function myLoop(time) {
            timer = setTimeout(function() {
                if (player.kifuReader.node.children.length != 0 && player.stop_auto === false) {
                    player.next();
                    myLoop(time)
                } else {
                    player.element.addEventListener(type, player._wheel_listener);
                    clearTimeout(timer)
                }
            }, time)
        }

        xxx_button1.addEventListener("click", function() {
            if (player.stop_auto) {
                player.element.removeEventListener(type, player._wheel_listener);
                player.stop_auto = false
                player.is_anal = 2
                anal_button.click()
                player.next();
                myLoop(1000)
            } else {
                player.stop_auto = true
            }
        })
        xxx_button2.addEventListener("click", function() {
            if (player.stop_auto) {
                player.element.removeEventListener(type, player._wheel_listener);
                player.stop_auto = false
                player.is_anal = 2
                anal_button.click()
                player.next();
                myLoop(3000)
            } else {
                player.stop_auto = true
            }
        })
        xxx_button3.addEventListener("click", function() {
            if (player.stop_auto) {
                player.element.removeEventListener(type, player._wheel_listener);
                player.stop_auto = false
                player.is_anal = 2
                anal_button.click()
                player.next();
                myLoop(5000)
            } else {
                player.stop_auto = true
            }
        })
    },
    assembleListener (is_new, is_bokgi, is_problem) {
        var unanal_board = function(e) {
            // update board's position
            if(e.change) this.board.update(e.change);

            // remove old markers from the board
            if(this.temp_marks) this.board.removeObject(this.temp_marks);

            // init array for new objects
            var add = [];

            this.notification();

            if (this.kifuReader.path.m > 0) {
                add.push({
                    type: "LB",
                    text: String(this.kifuReader.path.m),
                    x: this.kifuReader.node.move.x,
                    y: this.kifuReader.node.move.y,
                    c: (this.kifuReader.node.move.c === -1) ? "rgba(0,0,0,1)":"rgba(255,255,255,1)",
                })
            }

            // add other markup
            if(e.node.markup) {
                for(var i in e.node.markup) {
                    for(var j = 0; j < add.length; j++) {
                        if(e.node.markup[i].x == add[j].x && e.node.markup[i].y == add[j].y) {
                            add.splice(j,1);
                            j--;
                        }
                    }
                }
                add = add.concat(e.node.markup);
            }

            // add new markers on the board
            this.temp_marks = add;
            this.board.addObject(add);
        }

        var halfanal_board = function(e) {
            // update board's position
            if(e.change) this.board.update(e.change);

            // remove old markers from the board
            if(this.temp_marks) this.board.removeObject(this.temp_marks);

            // init array for new objects
            var add = [];

            this.notification();

            if (this.kifuReader.path.m > 0) {
                add.push({
                    type: "LB",
                    text: String(this.kifuReader.path.m),
                    x: this.kifuReader.node.move.x,
                    y: this.kifuReader.node.move.y,
                    c: (this.kifuReader.node.move.c === -1) ? "rgba(0,0,0,1)":"rgba(255,255,255,1)",
                })
            }

            if(e.node.children.length > 0 && this.config.displayVariations) {
                for(var i = 0; i < e.node.children.length; i++) {
                    if(e.node.children[i].move && !e.node.children[i].move.pass)	add.push({
                        type: "LB",
                        text: (is_bokgi) ? String.fromCharCode(65+i-1):String.fromCharCode(65+i),
                        x: e.node.children[i].move.x,
                        y: e.node.children[i].move.y,
                        c: this.board.theme.variationColor || "rgba(0,32,128,0.8)"
                    });
                }
            }

            // add other markup
            if(e.node.markup) {
                for(var i in e.node.markup) {
                    for(var j = 0; j < add.length; j++) {
                        if(e.node.markup[i].x == add[j].x && e.node.markup[i].y == add[j].y) {
                            add.splice(j,1);
                            j--;
                        }
                    }
                }
                add = add.concat(e.node.markup);
            }

            // add new markers on the board
            this.temp_marks = add;
            this.board.addObject(add);
        }

        var anal_board = function(e) {
            // update board's position
            if(e.change) this.board.update(e.change);

            // remove old markers from the board
            if(this.temp_marks) this.board.removeObject(this.temp_marks);

            // init array for new objects
            var add = [];

            this.notification();

            if (this.eventLog.length > 5) {
                this.eventLog.shift()
            }

            if (this.kifuReader.path.m === this.root_path) {
                this.kifuReader.node._last_selected = this.root_sel
                this.remember_root = false
            }

        
            // add move numbers
            if (is_bokgi === true || is_problem === true) {
                // when enter a variation, add numbers to stones
                if (this.remember_root === true && this.hover === false) {
                    var now_node = this.kifuReader.node
                    for (var i=0; i<this.kifuReader.path.m-this.root_path; i++) {
                        add.push({
                            type: "LB",
                            text: String(this.kifuReader.path.m-this.root_path-i),
                            x: now_node.move.x,
                            y: now_node.move.y,
                            c: (now_node.move.c === -1) ? "rgba(0,0,0,1)":"rgba(255,255,255,1)",
                        })
                        now_node = now_node.parent

                        if (i === this.kifuReader.path.m-this.root_path-i) {
                            add.push({
                                type: "CR",
                                x: this.root_x,
                                y: this.root_y,
                                c: "rgba(255,0,0,1)"
                            });
                        }
                    }
                // when going on the main sequence, add numbers to stones
                } else if (this.kifuReader.path.m > 0 && this.hover === false) {
                    add.push({
                        type: "LB",
                        text: String(this.kifuReader.path.m),
                        x: this.kifuReader.node.move.x,
                        y: this.kifuReader.node.move.y,
                        c: (this.kifuReader.node.move.c === -1) ? "rgba(0,0,0,1)":"rgba(255,255,255,1)",
                    })
                }
            // for merge case
            } else {
                if (this.kifuReader.path.m > 0 && this.hover === false) {
                    add.push({
                        type: "LB",
                        text: String(this.kifuReader.path.m),
                        x: this.kifuReader.node.move.x,
                        y: this.kifuReader.node.move.y,
                        c: (this.kifuReader.node.move.c === -1) ? "rgba(0,0,0,1)":"rgba(255,255,255,1)",
                    })
                }
            }

            // add variation letters
            if(e.node.children.length > 0 && this.config.displayVariations) {
                var next_color = (this.kifuReader.node.children[0].move.c === -1) ? "rgba(255,255,255,1)":"rgba(0,0,0,1)"

                var mainnode = e.node.children[0]
                if (is_new === false) {
                    var score_pos = 3
                } else {
                    var score_pos = 2
                }

                if (is_bokgi === false && is_problem === false) {
                    score_pos = 1
                } 

                var tmp_score;
                var bestnode_score;
                for(var i = 0; i < e.node.children.length; i++) {
                    // tmp_score = Number(e.node.children[i].comment.split(":")[score_pos])
                    try {
                        tmp_score = Number(e.node.children[i].comment.split(":")[score_pos])
                    } catch (err) {
                        tmp_score = 0
                    }
                    if (i === 0) {
                        bestnode_score = tmp_score;
                    } else if (tmp_score > bestnode_score) {
                        bestnode_score = tmp_score;
                    }
                }

                for(var i = 0; i < e.node.children.length; i++) {
                    if(e.node.children[i].move && !e.node.children[i].move.pass)	
                    {
                        // var lb_text = e.node.children[i].comment.split(':');
                        try {
                            var lb_text = e.node.children[i].comment.split(':');
                        } catch (err) {
                            var lb_text = ["0", "0", "0", "0", "0"];
                        }
                        var c_fill;
                        var width_circle;
                        var i_score = Number(lb_text[score_pos]);

                        if ((is_bokgi === true && is_new === true) || is_problem === true) {
                            if (bestnode_score === i_score) {
                                c_fill = "rgba(0,0,255,1.0)"
                            } else if ((bestnode_score - i_score) < 0.01) {
                                c_fill = "rgba(63,251,128,1.0)"
                            } else if ((bestnode_score - i_score) < 0.02) {
                                c_fill = "rgba(255,150,60,1.0)"
                            } else if ((bestnode_score - i_score) < 0.03) {
                                c_fill = "rgba(255,150,60,0.8)"
                            } else if ((bestnode_score - i_score) < 0.05) {
                                c_fill = "rgba(255,150,60,0.4)"
                            } else {
                                c_fill = "rgba(255,150,60,0.1)"
                            };
                        } else if (is_bokgi === true && is_new === false) {
                            if (bestnode_score === i_score) {
                                c_fill = "rgba(0,0,255,1.0)"
                            } else if ((bestnode_score - i_score) < 10) {
                                c_fill = "rgba(63,251,128,1.0)"
                            } else if ((bestnode_score - i_score) < 20) {
                                c_fill = "rgba(255,150,60,1.0)"
                            } else if ((bestnode_score - i_score) < 30) {
                                c_fill = "rgba(255,150,60,0.8)"
                            } else if ((bestnode_score - i_score) < 50) {
                                c_fill = "rgba(255,150,60,0.4)"
                            } else {
                                c_fill = "rgba(255,150,60,0.1)"
                            };
                        } else {
                            if (bestnode_score === i_score) {
                                c_fill = "rgba(0,0,255,1.0)"
                            } else if ((bestnode_score - i_score)/bestnode_score < 0.1) {
                                c_fill = "rgba(63,251,128,1.0)"
                            } else if ((bestnode_score - i_score)/bestnode_score < 0.2) {
                                c_fill = "rgba(255,150,60,1.0)"  
                            } else if ((bestnode_score - i_score)/bestnode_score < 0.3) {
                                c_fill = "rgba(255,150,60,0.8)"  
                            } else if ((bestnode_score - i_score)/bestnode_score < 0.5) {
                                c_fill = "rgba(255,150,60,0.4)"
                            } else {
                                c_fill = "rgba(255,150,60,0.1)"
                            };
                        }

                        // if (is_bokgi === false) {
                        //     if (bestnode_score === i_score) {
                        //         c_fill = "rgba(0,0,255,1.0)"
                        //     } else if ((bestnode_score - i_score)/bestnode_score < 0.1) {
                        //         c_fill = "rgba(63,251,128,1.0)"
                        //     } else if ((bestnode_score - i_score)/bestnode_score < 0.2) {
                        //         c_fill = "rgba(255,120,60,1.0)"  
                        //     } else if ((bestnode_score - i_score)/bestnode_score < 0.3) {
                        //         c_fill = "rgba(255,120,60,0.8)"  
                        //     } else if ((bestnode_score - i_score)/bestnode_score < 0.5) {
                        //         c_fill = "rgba(255,120,60,0.4)"
                        //     } else {
                        //         c_fill = "rgba(255,120,60,0.1)"
                        //     };

                        // } else if (is_new === false) {
                        //     if (bestnode_score === i_score) {
                        //         c_fill = "rgba(0,0,255,1.0)"
                        //     } else if ((bestnode_score - i_score) < 10) {
                        //         c_fill = "rgba(63,251,128,1.0)"
                        //     } else if ((bestnode_score - i_score) < 20) {
                        //         c_fill = "rgba(255,120,60,1.0)"
                        //     } else if ((bestnode_score - i_score) < 30) {
                        //         c_fill = "rgba(255,120,60,0.8)"
                        //     } else if ((bestnode_score - i_score) < 50) {
                        //         c_fill = "rgba(255,120,60,0.4)"
                        //     } else {
                        //         c_fill = "rgba(255,120,60,0.1)"
                        //     };
                        // } else {
                        //     if (bestnode_score === i_score) {
                        //         c_fill = "rgba(0,0,255,1.0)"
                        //     } else if ((bestnode_score - i_score) < 0.01) {
                        //         c_fill = "rgba(63,251,128,1.0)"
                        //     } else if ((bestnode_score - i_score) < 0.02) {
                        //         c_fill = "rgba(255,120,60,1.0)"
                        //     } else if ((bestnode_score - i_score) < 0.03) {
                        //         c_fill = "rgba(250,120,60,0.8)"
                        //     } else if ((bestnode_score - i_score) < 0.05) {
                        //         c_fill = "rgba(255,120,60,0.4)"
                        //     } else {
                        //         c_fill = "rgba(255,120,60,0.1)"
                        //     };
                        // }

                        if ((is_bokgi === true && is_new === true) || is_problem === true) {
                            if (i === 0 || (i != 0 && mainnode.move.x === e.node.children[i].move.x && mainnode.move.y === e.node.children[i].move.y)) {
                                width_circle = 5;
                            } else {
                                width_circle = 2;
                            }

                            lb_text = [String(Number(lb_text[0])*100), lb_text[1], lb_text[3]]
                        } else if (is_bokgi === true && is_new === false) {
                            if (i === 0 || (i != 0 && mainnode.move.x === e.node.children[i].move.x && mainnode.move.y === e.node.children[i].move.y)) {
                                width_circle = 5;
                                for (var path in this.kifuReader.path) {
                                    if (path != 'm') {
                                        if (this.kifuReader.path[path] != 0) {
                                            width_circle = 2;
                                        } 
                                    }
                                }
                            } else {
                                width_circle = 2;
                            }

                            lb_text = [lb_text[0], lb_text[1], lb_text[4]]
                        } else {
                            if (lb_text[4] === "T") {
                                width_circle = 5;
                            } else {
                                width_circle = 2;
                            }
                            lb_text = [String(Number(lb_text[0])*100), lb_text[1], lb_text[3]]
                        }

                        add.push({
                            type: "LB",
                            // text: String.fromCharCode(65+i),
                            text: lb_text,
                            x: e.node.children[i].move.x,
                            y: e.node.children[i].move.y,
                            c: this.board.theme.variationColor || "rgba(0,32,128,0.8)",
                            c_fill: c_fill,
                            c_circle: next_color,
                            width_circle: width_circle
                        });
                    }
                }
            }

            // add other markup
            if(e.node.markup) {
                for(var i in e.node.markup) {
                    for(var j = 0; j < add.length; j++) {
                        if(e.node.markup[i].x === add[j].x && e.node.markup[i].y === add[j].y) {
                            add.splice(j,1);
                            j--;
                        }
                    }
                }
                add = add.concat(e.node.markup);
            }

            // add new markers on the board
            this.temp_marks = add;
            this.board.addObject(add);
        }

        // hovering on variations
        var hover_variations = function(e) {
            var x = arguments[0],
                y = arguments[1]

            var childrens = this.kifuReader.node.children

            // duplicate_child, almost cases are bokgi. bokgi need to show the prediction of the main sequence
            var duplicate_child = false
            for (var i=1; i<childrens.length; i++) {
                if (childrens[i].move.x === childrens[0].move.x && childrens[i].move.y === childrens[0].move.y) {
                    duplicate_child = true
                }
            }

            var type = (document.onmousewheel !== undefined) ? "mousewheel" : "DOMMouseScroll";
            if ((childrens.length === 0 || this.is_limit) && this.hover === true) {
                if (x != this.last_x || y!= this.last_y) {
                    this.element.addEventListener(type, this._wheel_listener);
                    this.update()
                    this.hover = false
                    this.goTo(this.last_pos)
                    this.kifuReader.node._last_selected = this.last_sel;
                    this.is_limit = false
                }
            } else {
                for (var i=(duplicate_child)?1:0; i<childrens.length; i++) {
                    if (x === childrens[i].move.x && y === childrens[i].move.y && this.hover === false) {
                        this.hover = true
						this.element.removeEventListener(type, this._wheel_listener);
                        var add = []

                        if (this.kifuReader.path.m != 0) {
                            add.push({
                                type: "CR",
                                x: this.kifuReader.node.move.x,
                                y: this.kifuReader.node.move.y,
                                c: "rgba(255,0,0,1)"
                            });
                        }

                        this.last_pos = this.kifuReader.path.m || 0;
						this.last_sel = this.kifuReader.node._last_selected || 0;
                        this.last_x = x || 0;
                        this.last_y = y || 0;

                        this.notification()

                        var pred_order = 0
                        var flag = childrens.length
                        while (flag != 0) {
                            if (pred_order===0) {
                                this.next(i)
                            } else {
                                this.next(0)
                            }

                            pred_order += 1

                            add.push({
                                type: "LB",
                                text: String(pred_order),
                                x: this.kifuReader.node.move.x,
                                y: this.kifuReader.node.move.y,
                                c: (this.kifuReader.node.move.c === -1) ? "rgba(0,0,0,1)":"rgba(255,255,255,1)",
                                prefont: "italic bold "
                            })

                            if (pred_order > 19 && duplicate_child === false) {
                                flag = 0
                                this.is_limit = true
                            } else {
                                flag = this.kifuReader.node.children.length
                            }
                        }

						this.temp_marks = add;
						this.board.addObject(add);
                        this.eventLog.push("hover")
                    }
                }
            }
        }

        var click_variations =  function (e) {
            if(!this.kifuReader || !this.kifuReader.node) return false;
            var x = arguments[0],
                y = arguments[1];

            if (this.hover === true) {
                this.update();
                this.goTo(this.last_pos);
                this.kifuReader.node._last_selected = this.last_sel;
            }

            for (var i in this.kifuReader.node.children) {
                if(this.kifuReader.node.children[i].move && this.kifuReader.node.children[i].move.x === x && this.kifuReader.node.children[i].move.y === y) {
                    this.hover = false;
                    if (this.remember_root === undefined) this.remember_root = false;
                    if (this.remember_root === false && i != 0) {
                        this.remember_root = true
                        this.root_path = this.kifuReader.path.m || 0;
                        this.root_sel = this.kifuReader.node._last_selected
                        if (this.kifuReader.path.m != 0) {
                            this.root_x = this.kifuReader.node.move.x || 0;
                            this.root_y = this.kifuReader.node.move.y || 0;
                        }

                        this.travel_from_root = []
                    }

                    this.next(i);
                    var type = (document.onmousewheel !== undefined) ? "mousewheel" : "DOMMouseScroll";
                    this.element.addEventListener(type, this._wheel_listener);
                    break;
                }
            }
            this.eventLog.push("click")
        }

      this.player.listeners.update[0] = unanal_board.bind(this.player);
      this.player.unanal = this.player.listeners.update[0]
      this.player.listeners.update[0] = halfanal_board.bind(this.player);
      this.player.halfanal = this.player.listeners.update[0]
      this.player.listeners.update[0] = anal_board.bind(this.player);
      this.player.anal = this.player.listeners.update[0]
      this.player.update()
      
      // this.player.original_update_board = this.player.listeners.update[0]
      // delete this.player.listeners.update[0] 

      // this.new_update_board = anal_board
      // this.player.addEventListener('update', anal_board.bind(this.player));
      // this.player.update()

      this.player.hover_function = hover_variations.bind(this.player)
      this.player.click_function = click_variations.bind(this.player)
      this.player.board.addEventListener('mousemove', this.player.hover_function)
      this.player.board.addEventListener('click', this.player.click_function)
    },
    ...mapActions('auth', ['logout']),
    async checkIp() {
      const username = localStorage.getItem('user')
      const userip = localStorage.getItem('userIp')

      const payload = {
        email: username,
        ip: userip,
      }

      const path = 'https://' + SERVER_IP + ':8000/api/auth/ip'

      await axios.post(path, payload)
        .then(response => {
          if (response.data.data) {
          } else {
            this.logout().then(() => {
              this.$router.push({ path: '/login' })
              // reload login page
              this.$router.go()
            })
          }
          return console.log('read ok')
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },

  created: function () {
  },

  mounted: async function () {
    await this.checkIp()
    await this.readData()
    this.loadPlayer()
    try {
      this.makeChart(this.is_bokgi, this.charts)
      this.chartListener(this.is_bokgi)
    } catch(err) {
      console.log(err)
    }
    if (!this.player.kifuReader.node.children[0].comment) {
      this.is_new = true
    } else {
      if (this.player.kifuReader.node.children[0].comment.split(":").length === 5) {
        this.is_new = false
      } else {
        this.is_new = true
      }
    }
    this.assembleListener(this.is_new, this.is_bokgi, this.is_problem)
    this.boxListener(this.player)
    this.anal(this.is_problem, this.player)
    this.hover(this.is_problem, this.player)
    this.chart(this.is_problem, this.player)

	console.log(this.player)
    // var kifu = WGo.SGF.parse('(;FF[4]CA[UTF-8]AP[GoGui:1.5.1]KM[6.5]PB[HanEnYi]PW[�⑥��吏�]BR[P2��]WR[P8��]DT[2020- 8-27]RE[B+R]MULTIGOGM[1];B[pd];W[dp];B[pp];W[dd];B[cq];W[dq];B[cp];W[do])')
    // var kifuReader = new WGo.KifuReader(kifu, true, false)
    // this.player.kifu = kifu
    // this.player.kifuReader = kifuReader
    // this.player.update()
  },
}

</script>

<style>
  @import './assets/wgo/wgo.player.css';

  .wgo-button-root::before {
  	transform: scaleX(-1);
  	-moz-transform: scaleX(-1);
  	-webkit-transform: scaleX(-1);
  	-ms-transform: scaleX(-1);
  	color: rgba(255,0,0,0.8);
  }
  
  .wgo-button-root::before {
  	content: '\e80c';
  	transform: rotate(270deg);
  }

  .material-icons.anal {
  	color: royalblue;
  }

  .wgo-twocols .wgo-player-left, .wgo-twocols  .wgo-player-right  {
  	width: 50%;
  }
  
  .wgo-twocols .wgo-player-center {
  	width: 50%;
  }

  .wgo-player-right .wgo-player-wrapper, .wgo-player-left .wgo-player-wrapper {
    height: 60%;
    width: 100%;
    border-bottom: 0;
    background: rgba(255,255,255,1.0);
  }

  .wgo-player-right .wgo-commentbox, .wgo-player-left .wgo-commentbox {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 10px;
    top: 60%;
  }

  .wgo-autoplay-wrapper {
    height: 100px;
  }

  .wgo-autoplay {
    width: 33.333333%;
    margin: 0px;
    font-size: 30px;
    display: inline-block;
  }

  .autoplay {
    width: 99%;
    height: 100px;
    border: solid;
  }

  .wgo-chart-wrapper {
    width: 100%;
  }
</style>

