var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.width,"persistent":"","no-click-animation":""},model:{value:(_vm.details.show),callback:function ($$v) {_vm.$set(_vm.details, "show", $$v)},expression:"details.show"}},[_c('v-card',[_vm._t("title",[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.details.action == 'multiedit' ? _vm.$t('global.details.multipleUpdateTitle') : _vm.title)+" ")])],{"title":_vm.title}),_c('v-form',{model:{value:(_vm.details.formValid),callback:function ($$v) {_vm.$set(_vm.details, "formValid", $$v)},expression:"details.formValid"}},[_c('v-card-text',{staticClass:"details-list"},[_vm._t("over-fields"),_vm._l((_vm.fields),function(field,i){return _c('div',{key:i},[_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.details.action == 'multiedit' && field.show)?_c('v-flex',{staticClass:"sm1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(field.updateColumn),expression:"field.updateColumn"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(field.updateColumn)?_vm._i(field.updateColumn,null)>-1:(field.updateColumn)},on:{"change":function($event){var $$a=field.updateColumn,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(field, "updateColumn", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(field, "updateColumn", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(field, "updateColumn", $$c)}}}})]):_vm._e(),_c('v-flex',{class:_vm.details.action == 'multiedit' ? 'sm11' : 'sm12'},[_c('item-details-field',{attrs:{"field":field,"dynamic-field-type":_vm.dynamicFieldType(field.typeField),"field-value":field.value,"reload":_vm.reload},on:{"valueChanged":_vm.valueChanged},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var fieldType = ref.fieldType;
                  var field = ref.field;
                  var reload = ref.reload;
                  var rules = ref.rules;
                  var changeValue = ref.changeValue;
return [_vm._t(("field:" + (field.name)),null,{"value":value,"fieldType":fieldType,"field":field,"reload":reload,"rules":rules,"changeValue":changeValue})]}}],null,true)})],1)],1)],1)}),_vm._t("under-fields")],2),_c('v-card-actions',[_c('v-spacer'),_vm._t("custom-actions"),_c('v-btn',{attrs:{"color":"black","text":""},nativeOn:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('global.details.buttons.close')))]),(_vm.details.action == 'create')?_c('v-btn',{attrs:{"disabled":!_vm.details.formValid,"color":"green","text":""},on:{"click":function($event){return _vm.store()}}},[_vm._v(_vm._s(_vm.$t('global.details.buttons.create')))]):(_vm.details.action == 'edit')?_c('v-btn',{attrs:{"disabled":!_vm.details.formValid,"color":"orange","text":""},on:{"click":function($event){return _vm.update()}}},[_vm._v(_vm._s(_vm.$t('global.details.buttons.modify')))]):(_vm.details.action == 'multiedit')?_c('v-btn',{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.updateSelected()}}},[_vm._v(_vm._s(_vm.$t('global.details.buttons.modifySelected')))]):_vm._e()],2)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }