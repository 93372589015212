<template>
  <div>
    <v-data-table
      :headers="adminheaders"
      :items="show_categories"
      :search="search"
      :items-per-page="10"
      :page.sync="page"
      :footer-props="{
        itemsPerPageOptions: [10, 15, 20, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      @page-count="pageCount = $event"
      class="elevation-1 category-table"
    >
      <!--:footer-props="{
        itemsPerPageOptions: [10, 15, 20],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      @update:page="pageUpdateFunction"
      style="margin-top: 100px; margin-left: 300px; margin-right: 300px;"-->
      <template v-slot:top>
        <v-toolbar flat color="white">
          <h1>PATTERN PROBLEM</h1>
          <!--<h1>Games</h1>-->
          <!--<h3>[Bokgi Service]</h3>-->
          <p>&nbsp;</p>
          <div class="flex-grow-1"></div>

          <v-dialog v-model="dialog" max-width="500px">

            <template v-slot:activator="{ on }">
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <v-btn color="success" dark class="mb-2" @click="importTxt">Upload</v-btn>
              <v-file-input multiple accept=".sgf" label="Select File..." v-model="chosenFile"></v-file-input>
              <!--v-file-input  accept=".sgf" label="Select File..." v-model="chosenFile"></v-file-input-->
              <v-text-field v-if="uTest === true" class="starttext" label="Start Move"  v-model="startMove"></v-text-field>
              <v-text-field v-if="uTest === true" class="endtext" label="End Move"  v-model="endMove"></v-text-field>
              <!--<v-checkbox light label="Full Tree" v-model="fulltreeSelected"></v-checkbox>-->
            </template>

            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        style="width: 440px;"
                        v-model="editedItem.date"
                        label="Category Id:"
                        outlined
                      ></v-text-field>
                      <v-text-field
                        style="width: 440px;"
                        v-model="editedItem.name"
                        label="File name:"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <div class="flex-grow-1"></div>
                  <div v-if="dialogDelete === true">
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="remove">Delete</v-btn>
                  </div>
                  <div v-else-if="dialogDelete === false">
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                  </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-tabs v-model="tab">
          <v-tabs-slider color="black"></v-tabs-slider>
          <v-tab
            v-for="col in cols"
            :key="col"
          >
            {{col}}
          </v-tab>
          <v-tabs-items v-model="tab">
            {{record}}
          </v-tabs-items>
        </v-tabs>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn icon color="success" class="mr-2" @click="editItem(item)" to="/testbokgi" target="_blank" :key="item.state"><v-icon>visibility</v-icon></v-btn>
        <v-btn v-if="uAdmin === true" icon color="success" @click="downloadItem(item)"><v-icon>file_download</v-icon></v-btn>
        <v-btn v-else icon color="fail"><v-icon>file_download</v-icon></v-btn>
        <v-btn icon color="success" @click="deleteItem(item)"><v-icon>delete</v-icon></v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
    <div class="text-xs-center pt-2">
      <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapGetters } from 'vuex'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
// import iconv from 'iconv-lite'
var iconv = require("iconv-lite")
// var jschardet = require("jschardet")

// Add a request interceptor
axios.interceptors.request.use(
   config => {
       const token = localStorage.getItem('token')
       if (token) {
           config.headers['Authorization'] = 'Bearer ' + token
       }
       // config.headers['Content-Type'] = 'application/json';
       return config
   },
   error => {
       Promise.reject(error)
   })

// import categoriesData from '../../../../data/categories.json'
const { SERVER_IP } = require('../../../../config/server')
export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    search: '',
	uTest: false,
	uAdmin: false,
    show: true,
    dialogDelete: false,
    dialog: false,
    cols: ["normal", "op", "type"],
    tab: null,
    mergeheader: [
      { text: 'Merge', align: 'center', sortable: true, value:'merge' },
    ],
    mergeItem: [ 0 ],
    adminheaders: [
      { text: 'Name', align: 'left', sortable: true, value: 'name' },
      { text: 'Pattern', align: 'left', sortable: true, value: 'pattern' },
      { text: 'Difficulty', align: 'left', sortable: true, value: 'difficulty' },
      { text: 'Cut', align: 'left', sortable: true, value: 'cut' },
      { text: 'Actions', value: 'action', sortable: false, width: '12%' },
    ],
    headers: [
      { text: 'Name', align: 'left', sortable: true, value: 'name' },
      { text: 'Pattern', align: 'left', sortable: true, value: 'pattern' },
      { text: 'Difficulty', align: 'left', sortable: true, value: 'difficulty' },
      { text: 'Cut', align: 'left', sortable: true, value: 'cut' },
      { text: 'Actions', value: 'action', sortable: false, width: '12%' },
    ],
    categories: [],
    show_categories: [],
    // New Category
    editedIndex: -1,
    editedItem: {
      date: 0,
      name: '',
      state: '2',
    },
    defaultItem: {
      date: 0,
      name: '',
      state: '2',
    },
    chosenFile: null,
    data: null,
    // fulltreeSelected: true,
    shareSelected: true,
    startMove: 3,
    endMove: -1,
  }),

  // src/routes/app/getter.js
  computed: {
    record: function () {
        switch (this.tab) {
            case 1:
                var categories = []
                for (var game in this.categories) {
                  if (this.categories[game].name.includes('op') && this.categories[game].user.includes('badugi')) {
                    categories.push(this.categories[game])
                  } else {
                  }
                }
                this.show_categories = categories
                return
            case 2:
                var categories = []
                for (var game in this.categories) {
                  if (this.categories[game].name.includes('type') && this.categories[game].user.includes('badugi')) {
                    categories.push(this.categories[game])
                  } else {
                  }
                }
                this.show_categories = categories
                return
            default:
                var categories = []
                for (var game in this.categories) {
                  if (this.categories[game].name.includes('op') || this.categories[game].name.includes('type')) {
                  } else {
                    categories.push(this.categories[game])
                  }
                }
                this.show_categories = categories
                return
        }
    },
    ...mapGetters('auth', [
      'isLogged',
      'userInfo',
    ]),
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    // categories: function (val) {
    //     console.log('change to page', val)
    // },
  },

  created () {
    this.initialize()
    // 1000: 1 sec, 60000: 1 minute
    this.$options.interval = setInterval(this.updateDateTime, 60000)
  },

  mounted () {
    if (this.userInfo.name === 'test@goodi4u.com') {
      this.uTest = true
    } else {
      this.uTest = false
    }

    if (this.userInfo.name === 'badugi@goodi4u.com') {
      this.uAdmin = true
    } else {
      this.uAdmin = false
    }
  },

  beforeDestroy () {
    clearInterval(this.$options.interval)
  },

  methods: {
    initialize () {
      // this.categories = categoriesData
      const payload = {
        user_email: this.userInfo.name,
      }
      this.loadDB(payload)
    },

    rerender () {
        this.show = false
        this.$nextTick(() => {
            this.show = true
            // console.log('re-render start')
            this.$nextTick(() => {
                // console.log('re-render end')
            })
        })
    },

    // pageUpdateFunction(newPageNumber) {
    //   this.$router.push('/dashboard?page=' + this.page)
    // },

    updateDateTime () {
      // let now = new Date()
      // this.hours = now.getHours()
      // this.minutes = getZeroPad(now.getMinutes())
      // this.seconds = getZeroPad(now.getSeconds())
      // this.hourtime = getHourTime(this.hours)
      // this.hours = this.hours % 12 || 12
      // console.log('hello,world')
      // this.$router.go()
      // this.initialize()
      // this.forceUpdate()

      const payload = {
        user_email: this.userInfo.name,
      }

      const path = 'https://' + SERVER_IP + ':8000/download/problem'
      axios.post(path, payload)
        .then(response => {
          this.categories = []
          // console.log(response.data.data[0])
          for (var i = 0; i < response.data.data.length; ++i) {
              this.categories.push(response.data.data[i])
          }
          this.rerender()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

    // forceRerender () {
    //   // Remove my-component from the DOM
    //   this.renderComponent = false
    //   this.$nextTick(() => {
    //     // Add the component back in
    //     this.renderComponent = true
    //   })
    // },

    editItem (item) {
      localStorage.setItem('fn', item.path)
      localStorage.setItem('url', document.URL.split('/')[4])
    },

    downloadItem (item) {
      // this.dialogDelete = true
      // this.editedIndex = this.categories.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true

      if (!item.state) {
          return 0
      }

      // console.log(item.date)
      // console.log(item.name)
      // console.log(item.state)

      if (item.user) {
        this.userId = item.user+'@goodi4u.com'
	  } else {
        this.userId = this.userInfo.name
	  }

      const payload = {
        user_email: this.userId,
        file_name: item.name,
        file_date: item.date,
        file_state: item.state,
      }
      this.downloadRev(payload)
    },

    deleteItem (item) {
      if (!item.state) {
          return 0
      }

      if (item.user) {
        this.userId = item.user+'@goodi4u.com'
	  } else {
        this.userId = this.userInfo.name
	  }

      const payload = {
        user_email: this.userId,
        file_name: item.name,
        file_date: item.date,
      }
      // this.deleteRev(payload)
      var cfResult = confirm("DO YOU WANNA DELETE THIS FILE?")
      if (cfResult) {
        this.deleteRev(payload)
      } else {
        return 0
      }
    },


    // deleteItem (item) {
    //   this.dialogDelete = true
    //   this.editedIndex = this.categories.indexOf(item)
    //   this.editedItem = Object.assign({}, item)
    //   this.dialog = true
    // },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.dialogDelete = false
      }, 300)
    },

    save () {
      // Edited save
      if (this.editedIndex > -1) {
        Object.assign(this.categories[this.editedIndex], this.editedItem)
      // New save
      } else {
        this.categories.push(this.editedItem)
      }
      this.close()
    },

    remove () {
      this.categories.splice(this.editedIndex, 1)
      this.close()
    },

    // import sgf file
    async importTxt () {
      if (!this.chosenFile) { this.data = 'No File Chosen' }
      var isUnicode = false
      if(Array.isArray(this.chosenFile)){
        for (var i = 0; i<this.chosenFile.length; i++) {
          (function(that) {
            console.log(that)
            var reader = new FileReader()
            var tmp_data
            reader.onload = (e) => {
              var tmp_data = e.target.result
              if (tmp_data[5] === 'k') {
	            if (tmp_data.indexOf('ks_c_5601') === true) { console.log('indexof function : True') }
                console.log('ks_c_5601')
                const contents = Buffer.from(tmp_data, 'binary')
                // this.data = iconv.decode(contents, 'CP949').toString()
                tmp_data = iconv.decode(contents, 'CP949')
              } else {
                console.log('else : maybe utf-8')
                const contents = Buffer.from(tmp_data, 'binary')
                tmp_data = iconv.decode(contents, 'utf-8')
                // isUnicode = true
              }
              var payload = {
                user_email: that.userInfo.name,
                sgf_name: that.chosenFile[i].name,
                sgf_data: tmp_data,
                sgf_share: that.shareSelected,
	            start_move: that.startMove,
	            end_move: that.endMove,
              }
              that.sendData(payload)
              that.shareSelected = false
            }
            reader.readAsBinaryString(that.chosenFile[i])
          })(this) 
          await new Promise(r => setTimeout(r, 2000));
        }
      } else {
        var reader = new FileReader()
        reader.readAsBinaryString(this.chosenFile)
        reader.onload = () => {
          const tmp_data = reader.result
          if (tmp_data[5] === 'k') {
	        if (tmp_data.indexOf('ks_c_5601') === true) { console.log('indexof function : True') }
            console.log('ks_c_5601')
            const contents = Buffer.from(tmp_data, 'binary')
            // this.data = iconv.decode(contents, 'CP949').toString()
            this.data = iconv.decode(contents, 'CP949')
          } else {
            console.log('else : maybe utf-8')
            const contents = Buffer.from(tmp_data, 'binary')
            this.data = iconv.decode(contents, 'utf-8')
            // isUnicode = true
        }

          // console.log('user email: ' + this.userInfo.name)
          const payload = {
            user_email: this.userInfo.name,
            sgf_name: this.chosenFile.name,
            sgf_data: this.data,
            sgf_share: this.shareSelected,
	        start_move: this.startMove,
	        end_move: this.endMove,
          }
          this.sendData(payload)
          this.shareSelected = false
        }
      }
      this.initialize()
      this.data = null
      this.$router.go()
    },

    // import user's sgf file from backend db
    loadDB (payload) {
      const path = 'https://' + SERVER_IP + ':8000/download/problem'
      axios.post(path, payload)
        .then(response => {
          // console.log(response.data.data[0])
          for (var i = 0; i < response.data.data.length; ++i) {
              this.categories.push(response.data.data[i])
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

    // download user's review (game, tree) files from backend db
    downloadRev (payload) {
      const path = 'https://' + SERVER_IP + ':8000/download/file'
      const fn = payload.file_name.split('.')[0]
      axios.post(path, payload)
        .then(response => {

          var fileData = response.data.data[0].gibo
          var fileName = payload.file_name

          // var fd = fileName.split('.')[0]
          var fd = fileName.split('.')
          fd = fd.slice(0,fd.length-1).join('.')

          var zip = new JSZip()
          var img = zip.folder(fd)
          img.file( fileName, fileData, { base64: false } )

          if (response.data.data[0].tree) {
            var treeData = response.data.data[0].tree
            var treeName = 'montecarlo_move_all_bll_info.dat'
            img.file( treeName, treeData, { base64: false } )
          } 
          if (response.data.data[0].chart) {
            var chartData = response.data.data[0].chart
            var chartName = 'review.chart'
            img.file( chartName, chartData, { base64: false } )
          } 
          if (response.data.data[0].png) {
            var pngData = response.data.data[0].png
            var pngName = fd.concat('.png')
            img.file( pngName, pngData, { base64: true } )
          } 

          zip.generateAsync ({ type: 'blob' })
          .then(function (content) {
            // see FileSaver.js
            saveAs(content, fd.concat('.zip'))
          })
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },


    deleteRev (payload) {
      const path = 'https://' + SERVER_IP + ':8000/delete/file'
      // const fn = payload.file_name.split('.')[0]
      axios.post(path, payload)
        .then(response => {
          // console.log(response.data)
          // const fileData = response.data.data[0].game
          // const treeData = response.data.data[0].tree
          // const pngData = response.data.data[0].png
          // console.log(fileData)
	  this.updateDateTime()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

    sendData (payload) {
      const path = 'https://' + SERVER_IP + ':8000/upload/sgf'
      // axios.put(path, payload)
      axios.post(path, payload)
        .then(response => {
          // console.log(response.data.data[0])
          // this.categories.push(response.data.data[0])

          // this.initialize()
          // this.data = null
          // this.$router.go()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

  },
}
</script>

<style>
.category-table table th {
  background-color: lightgray;
  font-size: 20px !important;
}

</style>
