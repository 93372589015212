<template>
  <v-app>
    <v-app-bar
      app
      text floating
      color="indigo"
      class="shrink"
      v-if="this.$route.name != 'checkerboard' && this.$route.name != 'testbokgi' && this.$route.name != 'mergebokgi'"
    >
      <v-toolbar-title
        color="white"
        class="headline text-uppercase"
      >
        <span class="font-weight-light">GOOD INTELLIGENCE</span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text>
          <router-link to="/">
            Home
          </router-link>
        </v-btn>
        <!--<v-btn text>
          <router-link to="/project">
            project
          </router-link>
        </v-btn>
        <v-btn text>
          <router-link to="/team">Team</router-link>
        </v-btn>
        <v-btn text>
          <router-link to="/register">
            register
          </router-link>
        </v-btn>-->
        <v-btn text>
            <a href="https://www.youtube.com/channel/UCTYnCCawf4ojgxUsRgeRRXQ">youtube</a>
        </v-btn>
        <v-btn text>
          <router-link to="/login">
            login
          </router-link>
        </v-btn>
        <v-btn text>
          <router-link to="/problem">
            problem
          </router-link>
        </v-btn>
      </v-toolbar-items>

      <v-menu v-if="isMobile" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Menu
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :to="item.route"
            :key="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
    <transition mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
/* eslint-disable */
export default {
  data: () => ({
    items: [
      { title: 'Home', route: '/' },
      { title: 'Login', route: '/login' },
    ],
    isMobile: false,
    isFull: true,
  }),

  //    { title: 'Youtube', route: 'https://www.youtube.com/channel/UCTYnCCawf4ojgxUsRgeRRXQ' },

  // created() {
  //   window.addEventListener("resize", this._isFull);
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this._isFull);
  // },

  methods: {
    _isMobile: function(){
      var touchDevice = ('ontouchstart' in document.documentElement);
      return touchDevice;
    },
    // _isFull(e) {
	//   if (window.innerWidth == screen.width && window.innerHeight == screen.height) {
	//     return false;
	//   }
    // }
  },

  mounted(){
    this.isMobile = this._isMobile()
    // console.log('isMobile: ' + this.isMobile)
	window.addEventListener('resize', () => {
	 if (window.innerWidth == screen.width && window.innerHeight == screen.height) {
	   this.isFull = false;
	   // console.log(this);
	 } else {
	   this.isFull = true;
	 }
    });
  }
}
</script>

// .v-application {
//    font-family: $body-font-family, sans-serif !important;
//     .title { // To pin point specific classes of some components
//        font-family: $title-font, sans-serif !important;
//     }
//  }

<style lang="scss" scoped>
  .v-application {
    font-family: sans-serif;
  }
</style>
