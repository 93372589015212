import Dashboard from './routes/dashboard/Index.vue'
import Problemboard from './routes/problemboard/Index.vue'
import Adminboard from './routes/adminboard/Index.vue'
// import Checkerboard from './routes/checkerboard/Index.vue'
// import Checkerboard from './routes/checkerboard/bokgiapp/bokgiapp.html'
import Checkerboard from './routes/checkerboard/Gibo.vue'
import Cms from './routes/cms/Index.vue'
import cmsRoutes from './routes/cms/router'
import Store from './routes/store/Index.vue'
import storeRoutes from './routes/store/router'
import Administration from './routes/administration/Index.vue'
import administrationRoutes from './routes/administration/router'
// import Blog from './routes/blog/Index.vue'
// import blogRoutes from './routes/blog/router'
import Mergebokgi from './routes/testbokgi/Mergebokgi.vue'
import Testbokgi from './routes/testbokgi/Testbokgi.vue'
import Playbokgi from './routes/testbokgi/Playbokgi.vue'

let appRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/problemboard',
    name: 'problemboard',
    component: Problemboard,
  },
  {
    path: '/testbokgi',
    name: 'testbokgi',
    component: Testbokgi,
  },
  {
    path: '/mergebokgi',
    name: 'mergebokgi',
    component: Mergebokgi,
  },

  {
    path: '/adminboard',
    name: 'adminboard',
    component: Adminboard,
  },
  {
    path: '/checkerboard',
    name: 'checkerboard',
    component: Checkerboard,
  },
  {
    path: '/Playbokgi',
    name: 'Playbokgi',
    component: Playbokgi,
  },
  {
    path: 'cms',
    name: 'cms',
    component: Cms,
    children: cmsRoutes,
    meta: { guard: 'CMS' },
  },
  // {
  //   path: 'blog',
  //   name: 'blog',
  //   component: Blog,
  //   children: blogRoutes,
  //   meta: { guard: 'BLOG' },
  // },
  {
    path: 'store',
    name: 'store',
    component: Store,
    children: storeRoutes,
    meta: { guard: 'STORE' },
  },
  {
    path: 'administration',
    name: 'administration',
    component: Administration,
    children: administrationRoutes,
    meta: { guard: 'ADMIN' },
  },
]

export default appRoutes
