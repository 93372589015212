var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',[_c(_vm.componentLoader,{tag:"component",attrs:{"itemsViewConfig":_vm.itemsViewConfig,"meta":_vm.meta,"custom-buttons":_vm.customButtons,"item-elements":_vm.itemElements,"delete-mode":_vm.deleteMode,"edit-button":_vm.editButton,"table-fields":_vm.tableFields,"primary-key":_vm.primaryKey,"active-column-name":_vm.activeColumnName,"create-mode":_vm.createMode,"edit-mode":_vm.editMode,"main-filter":_vm.mainFilter,"field-filters":_vm.fieldFilters,"refresh-button":_vm.refreshButton,"export-button":_vm.exportButton,"select-many-mode":_vm.selectManyMode,"update-many-mode":_vm.updateManyMode,"remove-many-mode":_vm.removeManyMode},scopedSlots:_vm._u([_vm._l((_vm.tableFields),function(field){return {key:("field:" + (field.name)),fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._t(("list-item-field:" + (field.name)),null,{"item":item,"value":value})]}}})],null,true)}),_c('item-details',{attrs:{"title":_vm.detailsTitle,"details-fields":_vm.detailsFields,"width":_vm.itemDetailsWidth},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var title = ref.title;
return [_vm._t("item-details-title",null,{"title":title})]}},{key:"over-fields",fn:function(){return [_vm._t("item-details-over-fields")]},proxy:true},_vm._l((_vm.detailsFields),function(field){return {key:("field:" + (field.name)),fn:function(ref){
        var value = ref.value;
        var fieldType = ref.fieldType;
        var field = ref.field;
        var reload = ref.reload;
        var rules = ref.rules;
        var changeValue = ref.changeValue;
return [_vm._t(("item-details-field:" + (field.name)),null,{"value":value,"fieldType":fieldType,"field":field,"reload":reload,"rules":rules,"changeValue":changeValue})]}}}),{key:"under-fields",fn:function(){return [_vm._t("item-details-under-fields")]},proxy:true},{key:"custom-actions",fn:function(){return [_vm._t("item-details-custom-actions")]},proxy:true}],null,true)}),_c('item-elements'),_c('image-container')],1),_c('div',{staticClass:"details-loader-container"},[(_vm.detailsLoading)?_c('v-layout',{staticClass:"details-loader",attrs:{"justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":3,"color":"primary"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }