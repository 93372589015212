<template>
<div>
  <iframe v-if="loaded"
      :src="htmlCode"
      style ="width:100vw; height:100vh; border0px; overflow:hidden; margin:0px; padding: 0px; display:block;"
      frameBorder="0"
      scrolling="no"
      />
  <div id="loading" v-else style="width: 100%; height: 100%; position: fixed; z-index:1000; left:0px; top:0px;">Loading...</div>
</div>
</template>

<script>
/* eslint-disable */

import axios from 'axios'

const { SERVER_IP } = require('../../../../config/server')

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default {
  name: 'bokgiapp',
  data () {
    return {
      loaded: false,
      htmlCode: null,
      sgf:  '',
    }
  },

  methods: {
    readData () {

      const before_url = localStorage.getItem('url')

      const username = localStorage.getItem('ui')
      const filename = localStorage.getItem('fn')
      const filedate = localStorage.getItem('fd')

      const payload = {
        user_email: username,
        file_name: filename,
        file_date: filedate,
      }

      const path = 'https://' + SERVER_IP + ':8000/download/review'
      console.log(SERVER_IP)
      axios.post(path, payload)
        .then(response => {
          console.log('read ok')
          const fileData = response.data.gibo
          // console.log(fileData)
          this.sgf = fileData
          const chartData = response.data.charts
          // console.log(chartData)
          let tmpcharts = chartData

          var tmpHtml1 = `
                <!DOCTYPE HTML>
                <html>
                <head>
                <meta charset="utf-8">
                <title>WGo</title>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/wgo.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/kifu.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/sgfparser.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/player.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/basicplayer.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/basicplayer.component.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/basicplayer.infobox.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/basicplayer.control.js"><\/script>
	    		<script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/basicplayer.commentbox.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/player.editable.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/wgo/scoremode.js"><\/script>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/Chart/Chart.js"><\/script>
                <link rel="stylesheet" type="text/css" href="https://goodi4u.com/bokgiapp/wgo/wgo.player.css" />
                <link rel="stylesheet" href="https://goodi4u.com/bokgiapp/clean-switch.css">
                
                </head>
                <body>
                <div id ="helpme" style="width: 30vw; height: 35vh; position: absolute; z-index:2; left:55vw; top:10vh;">
                  <label class="cl-switch">
                    <input type="checkbox" id="varSwitch" >
                    <span class="switcher"></span>
                    <span class="label">Analysis</span>
                  </label>
                  <canvas id="myChart" style="width: 100%; height:90%" >`
         
          var tmpHtml2 = `</canvas>
	            </div>
                <div id="player2" style="width: 0px; position:relative; z-index:0; opacity:0.0">`
         
          var tmpHtml3 = `</div>
	    	    <div id="player" style="width:75vw; position: absolute; bottom:0vh">`
          var tmpHtml4 = `</div>
                <script type="text/javascript" src="https://goodi4u.com/bokgiapp/bokgi.js"><\/script>
                </body>
                </html>`

          // var tmpHtml3 = `</div>
	      //   <div id="player" style="width:45vw; display:block-inline; left:5vw; bottom:122vh">
          //       </div>
          //       <script type="text/javascript" src="https://goodi4u.com/bokgiapp/bokgi.js"><\/script>
          //       </body>
          //       </html>`

          var tmpHtml = tmpHtml1 + tmpcharts + tmpHtml2 + this.sgf + tmpHtml3 + filename + tmpHtml4
          this.htmlCode = 'data:text/html;charset=utf-8,' + encodeURI(tmpHtml)

          this.loaded = true
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },

  mounted: function () {
    this.readData()
  },
}
</script>

<style>
body {
  margin: 0;
}
</style>
