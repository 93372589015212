<template>
  <v-data-table
    v-if="show"
    :headers="headers"
    :items="categories"
    :items-per-page="10"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus'
    }"
    class="elevation-1 category-table"
    style="margin-top: 100px; margin-left: 300px; margin-right: 300px;"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <h1>ADMIN PAGE</h1>
        <p>&nbsp;</p>
        <div class="flex-grow-1"></div>

        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn color="success" dark class="mb-2" @click="importTxt">Upload</v-btn>
            <v-file-input accept=".sgf" label="Select File..." v-model="chosenFile"></v-file-input>
            <v-text-field label="Start Move"  v-model="startMove"></v-text-field>
            <v-checkbox light label="Full Tree" v-model="fulltreeSelected"></v-checkbox>
          </template>

          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      style="width: 440px;"
                      v-model="editedItem.date"
                      label="Category Id:"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="width: 440px;"
                      v-model="editedItem.name"
                      label="File name:"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
                <div v-if="dialogDelete === true">
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="remove">Delete</v-btn>
                </div>
                <div v-else-if="dialogDelete === false">
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </div>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon v-if="item.state == 'Done'" medium color="success" class="mr-2" @click="editItem(item)" :key="item.state">visibility</v-icon>
      <v-icon v-else medium color="fail" class="mr-2" >visibility_off</v-icon>
      <v-icon v-if="item.state == 'Done'" medium color="success" @click="downloadItem(item)">file_download</v-icon>
      <v-icon v-else medium color="fail">file_download</v-icon>
      <v-icon medium color="success" @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapGetters } from 'vuex'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
var iconv = require("iconv-lite")

// Add a request interceptor
axios.interceptors.request.use(
   config => {
       const token = localStorage.getItem('token')
       if (token) {
           config.headers['Authorization'] = 'Bearer ' + token
       }
       // config.headers['Content-Type'] = 'application/json';
       return config
   },
   error => {
       Promise.reject(error)
   })

// import categoriesData from '../../../../data/categories.json'
const { SERVER_IP } = require('../../../../config/server')
export default {
  data: () => ({
    show: true,
    dialogDelete: false,
    dialog: false,
    headers: [
      { text: 'Date', align: 'left', sortable: true, value: 'date' },
      { text: 'User', align: 'left', sortable: true, value: 'user' },
      { text: 'Name', align: 'left', sortable: true, value: 'name' },
      { text: 'Status', align: 'left', sortable: true, value: 'state' },
      { text: 'Actions', value: 'action', sortable: false, width: '8%' },
    ],
    categories: [],
    // New Category
    editedIndex: -1,
    editedItem: {
      date: 0,
      user: '',
      name: '',
      state: 'False',
    },
    defaultItem: {
      date: 0,
      user: '',
      name: '',
      state: 'False',
    },
    chosenFile: null,
    data: null,
    // fulltreeSelected: false,
    fulltreeSelected: true,
    startMove: 0,
  }),

  // src/routes/app/getter.js
  computed: {
    ...mapGetters('auth', [
      'isLogged',
      'userInfo',
    ]),
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    // categories: function (val) {
    //     console.log('change to page', val)
    // },
  },

  created () {
    this.initialize()
    // 1000: 1 sec, 60000: 1 minute
    this.$options.interval = setInterval(this.updateDateTime, 60000)
  },

  beforeDestroy () {
    clearInterval(this.$options.interval)
  },

  methods: {
    initialize () {
      // this.categories = categoriesData
      const payload = {
        user_email: this.userInfo.name,
      }
      this.loadDB(payload)
    },

    rerender () {
        this.show = false
        this.$nextTick(() => {
            this.show = true
            // console.log('re-render start')
            this.$nextTick(() => {
                // console.log('re-render end')
            })
        })
    },

    updateDateTime () {
      // let now = new Date()
      // this.hours = now.getHours()
      // this.minutes = getZeroPad(now.getMinutes())
      // this.seconds = getZeroPad(now.getSeconds())
      // this.hourtime = getHourTime(this.hours)
      // this.hours = this.hours % 12 || 12
      // console.log('hello,world')
      // this.$router.go()
      // this.initialize()
      // this.forceUpdate()

      const payload = {
        user_email: this.userInfo.name,
      }

      const path = 'https://' + SERVER_IP + ':8000/download/sgf'
      axios.post(path, payload)
        .then(response => {
          this.categories = []
          // console.log(response.data.data[0])
          for (var i = 0; i < response.data.data.length; ++i) {
            this.categories.push(response.data.data[i])
          }
          this.rerender()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

    // forceRerender () {
    //   // Remove my-component from the DOM
    //   this.renderComponent = false
    //   this.$nextTick(() => {
    //     // Add the component back in
    //     this.renderComponent = true
    //   })
    // },

    editItem (item) {
      // this.dialogDelete = false
      // this.editedIndex = this.categories.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true
      // console.log('go to checkerboard')
      // this.$router.push('/checkerboard')
      // 2020.06.11
      // userInfo, file_name, file_date
      // localStorage.setItem('ui', this.userInfo.name)
      const userId = item.user+'@goodi4u.com'
      // console.log('userId: ' + userId)
      localStorage.setItem('ui', userId)
      localStorage.setItem('fn', item.name)
      localStorage.setItem('fd', item.date)
      this.$router.push('/checkerboard')
    },

    downloadItem (item) {
      // this.dialogDelete = true
      // this.editedIndex = this.categories.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true

      if (!item.state) {
          return 0
      }

      // console.log(item.date)
      // console.log(item.name)
      // console.log(item.state)

      const payload = {
        // user_email: this.userInfo.name,
        user_email: item.user + '@goodi4u.com',
        file_name: item.name,
        file_date: item.date,
        file_state: item.state,
      }
      this.downloadRev(payload)
    },

    deleteItem (item) {
      if (!item.state) {
          return 0
      }

      const payload = {
        user_email: item.user + '@goodi4u.com',
        file_name: item.name,
        file_date: item.date,
      }
      this.deleteRev(payload)
    },

    // deleteItem (item) {
    //   this.dialogDelete = true
    //   this.editedIndex = this.categories.indexOf(item)
    //   this.editedItem = Object.assign({}, item)
    //   this.dialog = true
    // },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.dialogDelete = false
      }, 300)
    },

    save () {
      // Edited save
      if (this.editedIndex > -1) {
        Object.assign(this.categories[this.editedIndex], this.editedItem)
      // New save
      } else {
        this.categories.push(this.editedItem)
      }
      this.close()
    },

    remove () {
      this.categories.splice(this.editedIndex, 1)
      this.close()
    },

    // import sgf file
    importTxt () {
      if (!this.chosenFile) { this.data = 'No File Chosen' }
      var reader = new FileReader()
      // Use the javascript reader object to load the contents
      // of the file in the v-model prop
      // reader.readAsText(this.chosenFile)
      // reader.readAsText(this.chosenFile, 'ISO-8859-1')
      // reader.readAsText(this.chosenFile)
      reader.readAsBinaryString(this.chosenFile)
      reader.onload = () => {
        const tmp_data = reader.result    
        if (tmp_data[5] === 'k') {
          // var content2 = jschardet.detect(tmp_data);
          // console.log(content2);
          // {encoding: "EUC-KR", confidence: 0.99}
          // console.log('korean encoding')    
          const contents = Buffer.from(tmp_data, 'binary')
          // this.data = iconv.decode(contents, 'CP949').toString()
          this.data = iconv.decode(contents, 'CP949')
        } else {
          // console.log('unicode encoding')
          this.data = tmp_data
        }
        // console.log('user email: ' + this.userInfo.name)
        const payload = {
          user_email: this.userInfo.name,
          sgf_name: this.chosenFile.name,
          sgf_data: this.data,
          full_tree: this.fulltreeSelected,
		  start_move: this.startMove,
        }
        this.sendData(payload)
        this.fulltreeSelected = false
      }
    },

    // import user's sgf file from backend db
    loadDB (payload) {
      const path = 'https://' + SERVER_IP + ':8000/download/sgf'
      axios.post(path, payload)
        .then(response => {
          // console.log(response.data.data[0])
          for (var i = 0; i < response.data.data.length; ++i) {
              this.categories.push(response.data.data[i])
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

    // download user's review (game, tree) files from backend db
    downloadRev (payload) {
      const path = 'https://' + SERVER_IP + ':8000/download/file'
      const fn = payload.file_name.split('.')[0]
      axios.post(path, payload)
        .then(response => {
          // console.log(response.data)
          const fileData = response.data.data[0].game
          const treeData = response.data.data[0].tree
          const pngData = response.data.data[0].png
          // console.log(fileData)
          const fn = payload.file_name
          const fd = fn.split('.')[0]
          const tn = 'montecarlo_move_all_bll_info.dat'
          const pn = fd.concat('.png')
          var zip = new JSZip()
          var img = zip.folder(fd)
          img.file( fn, fileData, { base64: false } )
          img.file( tn, treeData, { base64: false } )
          img.file( pn, pngData, { base64: true } )
          zip.generateAsync ({ type: 'blob' })
          .then(function (content) {
            // see FileSaver.js
            saveAs(content, fd.concat('.zip'))
          })
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

    deleteRev (payload) {
      const path = 'https://' + SERVER_IP + ':8000/delete/file'
      // const fn = payload.file_name.split('.')[0]
      axios.post(path, payload)
        .then(response => {
          // console.log(response.data)
          // const fileData = response.data.data[0].game
          // const treeData = response.data.data[0].tree
          // const pngData = response.data.data[0].png
          // console.log(fileData)
	  this.updateDateTime()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

    // previous version:
    // downloadRev (payload) {
    //   const path = 'https://' + SERVER_IP + ':8000/download/file'
    //   const fn = payload.file_name.split('.')[0]
    //   axios.post(path, payload)
    //     .then(response => {
    //       // console.log(response.data)
    //       const fileData = response.data.data[0].result
    //       // console.log(fileData)
    //       const url = window.URL.createObjectURL(new Blob([fileData]))
    //       const link = document.createElement('a')
    //       link.href = url
    //       link.setAttribute('download', fn.concat('_', 'review.sgf'))
    //       document.body.appendChild(link)
    //       link.click()
    //       // console.log(response.data.data[0])
    //       // for (var i = 0; i < response.data.data.length; ++i) {
    //       //   this.categories.push(response.data.data[i])
    //       // }
    //     })
    //     .catch((error) => {
    //       // eslint-disable-next-line
    //       console.error(error)
    //     })
    // },
    // save sgf data to database
    sendData (payload) {
      const path = 'https://' + SERVER_IP + ':8000/upload/sgf'
      // axios.put(path, payload)
      axios.post(path, payload)
        .then(response => {
          // console.log(response.data.data[0])
          // this.categories.push(response.data.data[0])

          this.initialize()
          this.data = null
          this.$router.go()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    },

  },
}
</script>

<style>
.category-table table th {
  background-color: lightgray;
  font-size: 20px !important;
}
</style>
