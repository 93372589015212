var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('v-data-table',{staticClass:"elevation-1 category-table",staticStyle:{"margin-top":"100px","margin-left":"300px","margin-right":"300px"},attrs:{"headers":_vm.headers,"items":_vm.categories,"items-per-page":10,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-minus',
    nextIcon: 'mdi-plus'
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('h1',[_vm._v("ADMIN PAGE")]),_c('p'),_c('div',{staticClass:"flex-grow-1"}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success","dark":""},on:{"click":_vm.importTxt}},[_vm._v("Upload")]),_c('v-file-input',{attrs:{"accept":".sgf","label":"Select File..."},model:{value:(_vm.chosenFile),callback:function ($$v) {_vm.chosenFile=$$v},expression:"chosenFile"}}),_c('v-text-field',{attrs:{"label":"Start Move"},model:{value:(_vm.startMove),callback:function ($$v) {_vm.startMove=$$v},expression:"startMove"}}),_c('v-checkbox',{attrs:{"light":"","label":"Full Tree"},model:{value:(_vm.fulltreeSelected),callback:function ($$v) {_vm.fulltreeSelected=$$v},expression:"fulltreeSelected"}})]}}],null,false,3034035401),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticStyle:{"width":"440px"},attrs:{"label":"Category Id:","outlined":""},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}}),_c('v-text-field',{staticStyle:{"width":"440px"},attrs:{"label":"File name:","outlined":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(_vm.dialogDelete === true)?_c('div',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.remove}},[_vm._v("Delete")])],1):(_vm.dialogDelete === false)?_c('div',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1):_vm._e()])],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
  var item = ref.item;
return [(item.state == 'Done')?_c('v-icon',{key:item.state,staticClass:"mr-2",attrs:{"medium":"","color":"success"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("visibility")]):_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"fail"}},[_vm._v("visibility_off")]),(item.state == 'Done')?_c('v-icon',{attrs:{"medium":"","color":"success"},on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v("file_download")]):_c('v-icon',{attrs:{"medium":"","color":"fail"}},[_vm._v("file_download")]),_c('v-icon',{attrs:{"medium":"","color":"success"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true}],null,false,889021084)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }