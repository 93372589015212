<template>
<div>
  <div id="player" style="width: 160vh"></div>
</div>
</template>

<script>
/* eslint-disable */
const { SERVER_IP } = require('../../../../config/server')

import axios from 'axios'
import './assets/wgo/wgo.js'
import './assets/wgo/kifu.js'
import './assets/wgo/sgfparser.js'
import './assets/wgo/player.js'
import './assets/wgo/basicplayer.js'
import './assets/wgo/basicplayer.component.js'
import './assets/wgo/basicplayer.commentbox.js'
import './assets/wgo/basicplayer.infobox.js'
import './assets/wgo/basicplayer.control.js'
import './assets/wgo/player.editable.js'
import './assets/wgo/scoremode.js'
import './assets/bokgi.js'

export default {
  name: 'bokgiapp',
  data () {
    return {
      loaded: false,
      htmlCode: null,
      defaultSgf:  '(;KM[6.5])',
      userColor: 1,
      myTime: 20,
      yourTime: 20,
      p_num: null,
    }
  },

  methods: {
    async loadPlayer () {
	  if (localStorage.getItem('play_kifu') != 'null') { 
        const url = 'https://' + SERVER_IP + ':8088/play/set'
        const payload = {
            board: '[' + localStorage.getItem('play_kifu') + ']',
            move_save: localStorage.getItem('play_move_save'),
        }

        axios.post(url, payload)
          .then(response => {

          })
            .catch((error) => {
            console.error(error)
          })
      } else {
        var elem = document.getElementById("player")
        this.player = new WGo.BasicPlayer(elem, {
	      layout: WGo.BasicPlayer.default.layout[1],
          enableKeys: false,
          enableWheel: false,
          sgf: this.defaultSgf,
        })
      }
    },
    togglePlayable () {
	  this._editable = this._editable || new WGo.Player.Editable(this.player, this.player.board);
	  this._editable.set(!this._editable.editMode);
    },
    addPASS (player) {
      const bottom = document.getElementsByClassName('wgo-player-bottom-wrapper')[0];
      const pass_button = document.createElement('button');
      pass_button.className = 'pass';
      pass_button.innerHTML = 'pass';
      bottom.appendChild(pass_button);

	  pass_button.addEventListener("click", function() {
        const url = 'https://' + SERVER_IP + ':8088/play/save'
        const payload = {
            pass: 'PASS',
        }

	    player.kifuReader.node.appendChild(new WGo.KNode({
	    	move: {
	    		pass: true, 
	    		c: player.kifuReader.game.turn
	    	}, 
	    	// _edited: true
	    }));

	    player.next(player.kifuReader.node.children.length-1);

        axios.post(url, payload)
          .then(response => {

	        var xx = response.data.x
	        var yy = response.data.y
	        
	        player.kifuReader.node.appendChild(new WGo.KNode({
	        	move: {
	        		x: xx, 
	        		y: yy, 
	        		c: player.kifuReader.game.turn
	        	}, 
	        	// _edited: true
	        }));
	        player.next(player.kifuReader.node.children.length-1);
          })
            .catch((error) => {
            console.error(error)
          })
      })
	},
    removeListeners () {
	  this.player.board.removeEventListener("click", this._editable._ev_click);
	  this.player.board.removeEventListener("mousemove", this._editable._ev_move);
	  this.player.board.removeEventListener("mouseout", this._editable._ev_out);
	},
    addListeners () {
	  this.player.board.addEventListener("click", this._editable._ev_click);
	  this.player.board.addEventListener("mousemove", this._editable._ev_move);
	  this.player.board.addEventListener("mouseout", this._editable._ev_out);
	},
    endGame () {
      const url = 'https://' + SERVER_IP + ':8088/play/end'
      axios.post(url)
        .then(response => {
          console.log(response.data.success)
        })
          .catch((error) => {
          console.error(error)
        })
	},
    doMove (x, y) {
      const url = 'https://' + SERVER_IP + ':8088/play/save'

      const payload = {
          x: x,
          y: y,
          p_num: this.p_num,
      }

	  this.player.kifuReader.node.appendChild(new WGo.KNode({
	  	move: {
	  		x: x, 
	  		y: y, 
	  		c: this.player.kifuReader.game.turn
	  	}, 
	  	// _edited: true
	  }));

	  this.player.next(this.player.kifuReader.node.children.length-1);
      this.player.board.removeEventListener("click", this.newUpdater)

      axios.post(url, payload)
        .then(response => {

	      var xx = response.data.x
	      var yy = response.data.y
	      
	      this.player.kifuReader.node.appendChild(new WGo.KNode({
	      	move: {
	      		x: xx, 
	      		y: yy, 
	      		c: this.player.kifuReader.game.turn
	      	}, 
	      	// _edited: true
	      }));
	      this.player.next(this.player.kifuReader.node.children.length-1);
          this.player.board.addEventListener("click", this.newUpdater)
        })
          .catch((error) => {
          console.error(error)
        })
	},
	newUpdater (x, y) {
	  this.doMove(x, y)
	},
    onoffFunc () {
      const onoff_button = document.getElementsByClassName('onoff')[0];
      if (onoff_button.innerHTML === 'start'){
        onoff_button.innerHTML = 'loading...'
        const url = 'https://' + SERVER_IP + ':8088/play/start'
        axios.post(url)
          .then(response => {
            this.p_num = response.data.p_num
            this.player.board.addEventListener("click", this.doMove)
            onoff_button.innerHTML = 'resign'
          })
            .catch((error) => {
            console.error(error)
          })
      } else if (onoff_button.innerHTML === 'reset') {
        location.reload()
      } else {
        const url = 'https://' + SERVER_IP + ':8088/play/end'
	    const payload = {
          p_num: this.p_num
	    }
        axios.post(url, payload)
          .then(response => {
            console.log(response.data.success)
            onoff_button.innerHTML = 'reset'
          })
            .catch((error) => {
            console.error(error)
          })
      }
	},
    addOnoff (player, newUpdater, p_num) {
      const bottom = document.getElementsByClassName('wgo-player-bottom-wrapper')[0];
      const onoff_button = document.createElement('button');
      onoff_button.className = 'onoff';
      onoff_button.innerHTML = 'start';
      bottom.appendChild(onoff_button);

	  onoff_button.addEventListener("click", this.onoffFunc)
	},
  },

  created: function () {
    document.addEventListener('beforeunload', this.endGame)
  },

  mounted: async function () {
    this.loadPlayer()
    this.addOnoff(this.player, this.newUpdater, this.p_num)
    this.addPASS(this.player)
    // this.togglePlayable()
  },

  beforeDestroy: function () {
  },
}

</script>

<style>
  @import './assets/wgo/wgo.player.css';

  .onoff {
    width: 30%;
    height: 50px;
    border: solid;
  }

  .pass {
    width: 30%;
    height: 50px;
    border: solid;
	float: right;
  }
</style>

